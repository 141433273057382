import "./TemplateChoice.css";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import Headers from "../../../Component/Headers/Headers";
import { TemplateCard } from "../../../Component/TemplateCard/TemplateCard";
import { TemplateModel } from "../../../models/Template.model";
import { TemplateService } from "../../../service/template/Template.service";
import { TemplatePreview } from "../../../Component/TemplatePreview/TemplatePreview";
import { ProgramModel } from "../../../models/Program.model";
import { ProgramService } from "../../../service/program/Program.service";
import { TemplateInterface } from "../../../models/ITemplate.model";
import InfoToUser from "../../../Component/InfoToUser/InfoToUser";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function templatesRelatedToTopic(index: number) {
  return {
    id: `templates-${index}`,
    "aria-controls": `templates-${index}`,
  };
}

function similarAndOlder(index: number) {
  return {
    id: `similar-and_older-${index}`,
    "aria-controls": `similar-and_older-${index}`,
  };
}

export function TemplateChoiceMainTrainer(props: {
  topic: string;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setDisplayProgram: Dispatch<SetStateAction<boolean | null>>;
}) {
  const [templates, setTemplates] = useState<TemplateModel[]>([]);
  const [oldPrograms, setOldPrograms] = useState<ProgramModel[]>([]);
  const [similarPrograms, setSimilarPrograms] = useState<ProgramModel[]>([]);
  const [open, setOpen] = useState(false);
  const [template, setTemplate] = useState<TemplateModel | null>(null);

  useEffect(() => {
    TemplateService.getSimilarTemplates().then((templates) => {
      setTemplates(templates);
    });
    ProgramService.getOldPrograms().then((programs) => {
      setOldPrograms(programs);
    });
    ProgramService.getSimilarPrograms().then((programs) => {
      setSimilarPrograms(programs);
    });
  }, []);

  const openPreview = (template: TemplateInterface | null) => {
    setTemplate(template);
    setOpen(true);
  };

  // --- TABS ---
  //First tab = Template related to the topic
  const [firstTab] = React.useState(0);

  //Second tab = Multiple tabs: older and similar programs
  const [value, setValue] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div>
      <Headers title={"Choix du modèle"} save={null} />

      {/* --- Topic --- */}
      <Box
        sx={{
          color: "primary.main",
          ml: "200px",
          mr: "200px",
          textAlign: "center",
          padding: "20px",
          paddingTop: "130px",
        }}
      >
        <h2> Votre thématique : {props.topic} </h2>
      </Box>

      {/* Information to display to the main trainer */}
      <InfoToUser
        text={
          "Cliquez sur un modèle pour en afficher la preview avant de le sélectionner."
        }
        type={"info"}
      />
      <InfoToUser
        text={
          "Une fois la preview affichée, vous pouvez choisir le template pour débuter votre " +
          "programme de formation. Toutes les informations du template seront placées dans " +
          "votre programme et vous pourrez modifier votre programme comme vous le souhaitez."
        }
        type={"info"}
      />

      {/* --- Templates related to the topic --- */}
      <Box
        sx={{
          bgcolor: "info.light",
          color: "primary.dark",
          ml: "200px",
          mr: "200px",
          padding: "20px",
        }}
      >
        {/* Templates related to the topic tab */}
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={firstTab}
              variant="fullWidth"
              disabled
              sx={{
                textAlign: "left",
              }}
            >
              <Tab
                sx={{
                  textAlign: "left",
                }}
                disableRipple
                label="Modèles en rapport avec la thématique"
                {...templatesRelatedToTopic(0)}
              />
            </Tabs>
          </Box>

          {/* Display of the templates related to the topic */}
          <TabPanel value={firstTab} index={0}>
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              {/* Empty model */}
              <TemplateCard
                title={"Vierge"}
                template={null}
                handleClick={openPreview}
              />
              {templates.map((template) => (
                <TemplateCard
                  title={template.title}
                  template={template}
                  handleClick={openPreview}
                  key={template._id}
                />
              ))}
            </div>
          </TabPanel>
        </Box>
      </Box>

      {/* --- Older programs and similar programs --- */}
      <Box
        sx={{
          bgcolor: "info.light",
          color: "primary.dark",
          ml: "200px",
          mr: "200px",
          padding: "20px",
          marginBottom: "50px",
        }}
      >
        {/* The different tabs */}
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label="old and similar programs tabs"
          >
            <Tab label="Autres programmes similaires" {...similarAndOlder(1)} />
            <Tab label="Vos anciens programmes" {...similarAndOlder(0)} />
          </Tabs>
        </Box>

        {/* Old programs */}
        <TabPanel value={value} index={0}>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            {oldPrograms.length === 0 && <p>Pas de programme</p>}
            {oldPrograms.map((template) => (
              <TemplateCard
                title={template.title}
                template={template}
                handleClick={openPreview}
                key={template._id}
              />
            ))}
          </div>
        </TabPanel>

        {/* Other similar programs */}
        <TabPanel value={value} index={1}>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            {similarPrograms.length === 0 && <p>Pas de programme</p>}
            {similarPrograms.map((template) => (
              <TemplateCard
                title={template.title}
                template={template}
                handleClick={openPreview}
                key={template._id}
              />
            ))}
          </div>
        </TabPanel>
      </Box>

      {/* --- The template preview --- */}
      <TemplatePreview
        open={open}
        setOpen={setOpen}
        setTemplate={setTemplate}
        template={template}
        setLoading={props.setLoading}
        setDisplayProgram={props.setDisplayProgram}
      />
    </div>
  );
}
