import {createTheme} from '@mui/material/styles';

export const themeMatchersLight = createTheme({
    palette: {
        //Bleu roi
        primary: {
            light: '#7066C7',
            main: '#1101a2',
            dark: '#0C0071'
        },
        //Bleu clair
        secondary: {
            light: '#66d2e8',
            main: '#00B4D8',
            dark: '#0090AD'
        },
        //Orange
        warning: {
            light: '#ffb666',
            main: '#FF8500',
            dark: '#E67800'
        },
        //Rouge
        error: {
            light: '#FF6262',
            main: '#FB4E4E',
            dark: '#E93C3C'
        },
        //Gris
        info: {
            light: '#F6F6F6',
            main: '#DDDDDD',
            dark: '#979797'
        },
        //Vert
        success: {
            light: '#40DD7F',
            main: '#2AC769',
            dark: '#1AB759'
        }
    },

    typography: {
        fontFamily: [
          'Comfortaa',
          'cursive',
          'Poppins',
          'sans-serif',
          'Roboto',
        ].join(','),
      },

});
