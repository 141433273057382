import { Box, Collapse, Alert, IconButton } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

export default function InfoToUser(props: { text: string; type: string }) {
  // Information to the user (alert)
  const [openInfo, setOpenInfo] = React.useState(true);
  const color = props.type + ".light";

  return (
    /* Information to display to the user */
    <Box sx={{ width: "70%", margin: "auto", mb: "15px" }}>
      <Collapse in={openInfo}>
        <Alert
          severity="info"
          sx={{ bgcolor: color, mb: 2 }}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpenInfo(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {props.text}
        </Alert>
      </Collapse>
    </Box>
  );
}
