import axios from "axios";
import { plainToInstance } from "class-transformer";
import { TemplateModel } from "../../models/Template.model";

export class TemplateService {
  /**
   * Retrieves a template from the DB with its id
   * @param data
   * @returns the template
   */
  public getTemplate(id: string): Promise<TemplateModel> {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.REACT_APP_API_URL + "/template", {
          params: { id: id },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Retrieves all the templates from the DB
   * @returns the list of templates
   */
  public static getAllTemplates(): Promise<TemplateModel[]> {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.REACT_APP_API_URL + "/template/getAll")
        .then((response) => {
          let template = response.data.template;
          let templates = plainToInstance(TemplateModel, template);
          console.log(templates);
          // @ts-ignore
          resolve(templates);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Retrieves all the templates that have the same topic in the DB
   * @param topic the topic we want to get all the templates to have
   * @returns the list of similar templates
   */
  public static getSimilarTemplates(): Promise<TemplateModel[]> {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.REACT_APP_API_URL + "/template/getSimilar")
        .then((response) => {
          let templateJson = response.data;
          let templates = plainToInstance(TemplateModel, templateJson);
          // @ts-ignore
          resolve(templates);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  //delete
  public static delete(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.REACT_APP_API_URL + "/template/delete", {
          params: { id: id },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  //update
  public update(id: string, title: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .patch(process.env.REACT_APP_API_URL + "/template/update", {
          params: { id: id, title: title },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
