import React, { Dispatch, SetStateAction, useContext } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { ProgramContext } from "./context/program.context";
import { RoleType, UserContext } from "./context/user.context";
import TrainerPage from "./page/trainerPage/trainerPage";
import ClientPage from "./page/clientPage/clientPage";
import MainTrainerPage from "./page/mainTrainerPage/mainTrainerPage";

function App(props: {
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  displayProgram: boolean | null;
  setDisplayProgram: Dispatch<SetStateAction<boolean | null>>;
}) {
  const [program, setProgram] = useContext(ProgramContext);
  const [user] = useContext(UserContext);

  if (user && program) {
    if (user.role === RoleType.MAIN_TRAINER) {
      return (
        <>
          <Backdrop sx={{ color: "#fff", zIndex: 9001 }} open={props.loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <MainTrainerPage
            program={program}
            setProgram={setProgram}
            displayProgram={props.displayProgram}
            loading={props.loading}
            setDisplayProgram={props.setDisplayProgram}
            setLoading={props.setLoading}
          />
        </>
      );
    } else if (user.role === RoleType.TRAINER) {
      return (
        <>
          <Backdrop sx={{ color: "#fff", zIndex: 9001 }} open={props.loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <TrainerPage
            program={program}
            setProgram={setProgram}
            displayProgram={props.displayProgram}
            loading={props.loading}
            setDisplayProgram={props.setDisplayProgram}
            setLoading={props.setLoading}
          />
        </>
      );
    } else if (user.role === RoleType.ADMIN) {
      return (
        <>
          <Backdrop sx={{ color: "#fff", zIndex: 9001 }} open={props.loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <MainTrainerPage
            program={program}
            setProgram={setProgram}
            displayProgram={props.displayProgram}
            loading={props.loading}
            setDisplayProgram={props.setDisplayProgram}
            setLoading={props.setLoading}
          />
        </>
      );
    } else {
      return <ClientPage program={program} setProgram={setProgram} />;
    }
  } else {
    return (
      <Backdrop sx={{ color: "#fff", zIndex: 9001 }} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
}

export default App;
