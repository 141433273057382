import React from "react";
import ReactDOM from "react-dom";
import "reflect-metadata";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider, Zoom } from "@mui/material";
import { themeMatchersLight } from "./theme";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { SnackbarProvider } from "notistack";
import ProviderProgram from "./ProviderProgram";
import Error404Page from "./page/page404/error404";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <DndProvider backend={HTML5Backend}>
        <ThemeProvider theme={themeMatchersLight}>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
            autoHideDuration={8000}
            TransitionComponent={Zoom}
            preventDuplicate={true}
          >
            <Routes>
              <Route path="/:token" element={<ProviderProgram />} />
              <Route path="*" element={<Error404Page />} />
            </Routes>
          </SnackbarProvider>
        </ThemeProvider>
      </DndProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
