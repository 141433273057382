import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardHeader,
  Stack,
} from "@mui/material";
import "./SuggestionCard.css";
import { ModuleSuggestionInterface } from "../../models/IModuleSuggestion.model";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ProgramContext } from "../../context/program.context";
import {
  AssetsContext,
  EvaluationContext,
  GoalsContext,
  ModalitiesContext,
  PrerequisitesContext,
  PublicsContext,
} from "../../context/element.context";
import { ElementModel, ElementType } from "../../models/Element.model";
import { ElementService } from "../../service/program/Element.service";
import { ModuleService } from "../../service/program/Module.service";
import { ModuleModel } from "../../models/Module.model";
import {
  ModuleSelected,
  SkillSelected,
  TypeContext,
} from "../../context/type.context";
import { useDrag } from "react-dnd";
import { useSnackbar } from "notistack";
import { themeMatchersLight } from "../../theme";

export function SuggestionCard(props: {
  element: ModuleSuggestionInterface;
  suggestionList: ModuleSuggestionInterface[];
  setSuggestionList: React.Dispatch<
    React.SetStateAction<ModuleSuggestionInterface[]>
  >;
  index: number;
}) {
  const [selectedType] = useContext(TypeContext);
  const [program] = useContext(ProgramContext);
  const [goals, setGoals] = useContext(GoalsContext);
  const [modalities, setModalities] = useContext(ModalitiesContext);
  const [publics, setPublics] = useContext(PublicsContext);
  const [prerequisites, setPrerequisites] = useContext(PrerequisitesContext);
  const [evaluation, setEvaluation] = useContext(EvaluationContext);
  const [assets, setAssets] = useContext(AssetsContext);
  const { enqueueSnackbar } = useSnackbar();
  const ErrorDisplay = (err: any) => {
    switch (err.status) {
      case 500:
        enqueueSnackbar(err.data.error ? err.data.error : err.statusText, { variant: "error" });
        break;
      case 400:
        enqueueSnackbar(err.data.error ? err.data.error : err.statusText, { variant: "error" });
        break;
      default:
        enqueueSnackbar(err.statusText, { variant: "error" });
    }
  };

  const addElement = (element: ModuleSuggestionInterface) => {
    if (element instanceof ModuleModel) {
      if (selectedType instanceof ModuleSelected) {
        if (selectedType.subModule) {
          if (selectedType.id) {
            ModuleService.addModuleInModuleFromSuggestion(
              element._id,
              selectedType.id
            )
              .then((module) => {
                selectedType.setModules((previousValue) => {
                  return [...previousValue, module];
                });
              })
              .catch(ErrorDisplay);
          } else {
            console.log("Missing id");
          }
        } else {
          ModuleService.addModuleInTrainingProgramFromSuggestion(element._id)
            .then((module) => {
              selectedType.setModules((previousValue) => {
                return [...previousValue, module];
              });
            })
            .catch(ErrorDisplay);
        }
      } else {
        console.log("error selected type");
      }
    } else if (element instanceof ElementModel) {
      switch (element.type) {
        case ElementType.GOAL:
          ElementService.addGoal(element.text, element._id)
            .then((goal) => {
              setGoals([...goals, goal]);
            })
            .catch(ErrorDisplay);

          break;
        case ElementType.MODALITY:
          ElementService.addModality(element.text, element._id)
            .then((modality) => {
              setModalities([...modalities, modality]);
            })
            .catch(ErrorDisplay);

          break;
        case ElementType.PREREQUISITE:
          ElementService.addPrerequisite(element.text, element._id)
            .then((prerequisite) => {
              setPrerequisites([...prerequisites, prerequisite]);
            })
            .catch(ErrorDisplay);
          break;
        case ElementType.PUBLIC:
          ElementService.addPublic(element.text, element._id)
            .then((publicAdded) => {
              setPublics([...publics, publicAdded]);
            })
            .catch(ErrorDisplay);
          break;
        case ElementType.ASSET:
          ElementService.addAsset(element.text, element._id)
            .then((asset) => {
              setAssets([...assets, asset]);
            })
            .catch(ErrorDisplay);
          break;
        case ElementType.EVALUATION:
          ElementService.addEvaluation(element.text, element._id)
            .then((e) => {
              setEvaluation([...evaluation, e]);
            })
            .catch(ErrorDisplay);
          break;
        case ElementType.SKILL:
          if (selectedType instanceof SkillSelected) {
            ElementService.addSkill(
              selectedType.moduleId,
              element.text,
              element._id
            )
              .then((skill) => {
                selectedType.setSkills([...selectedType.skills, skill]);
              })
              .catch(ErrorDisplay);
          }
          break;
      }
    } else {
      console.log("error");
    }
  };
  const [, drag, preview] = useDrag(
    () => ({
      type: props.element.getType(),
      item: props.element,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [props.element]
  );

  const handleClick = () => {
    const liste = props.suggestionList.filter((element) => {
      return element !== props.element;
    });
    props.setSuggestionList(liste);
    // BD
    if (program) {
      addElement(props.element);
    }
  };

  const [showMore, setShowMore] = useState(false);
  const [height, setHeight] = useState(0);
  const [maxheight, setMaxHeight] = useState("300px");
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      // @ts-ignore
      setHeight(ref.current!.clientHeight);
    }
  }, [ref, height]);

  if (props.element instanceof ElementModel) {
    return (
      <div className="card">
        <Card ref={drag}>
          <CardActionArea ref={preview} onClick={handleClick}>
            <CardHeader
              avatar={<div className="type">{props.element.getType()}</div>}
              sx={{
                "& .MuiCardHeader-avatar": {
                  color: "warning.main",
                },
              }}
            />
            <Box sx={{ padding: "16px", paddingTop: "0" }}>
              {"• " + props.element.toString()}
            </Box>
          </CardActionArea>
        </Card>
      </div>
    );
  } else if (props.element instanceof ModuleModel) {
    return (
      <div className="card">
        <Card ref={drag}>
          <CardActionArea ref={preview} onClick={handleClick}>
            <CardHeader
              avatar={
                <div className="type">{props.element.getModuleDeep()}</div>
              }
              sx={{
                "& .MuiCardHeader-avatar": {
                  color: "warning.main",
                },
              }}
            />
            <Box
              sx={{
                padding: "16px",
                paddingTop: "0",
                maxHeight: maxheight,
                overflow: "hidden",
              }}
            >
              <div ref={ref}>
                <h3>{props.element.toString()}</h3>
                {props.element.modules.map((module, index) => {
                  return (
                    <div key={module._id}>
                      <h4>{index + 1 + "- " + module}</h4>
                      {module.skills.map((skill) => (
                        <li key={skill._id}>
                          <label key={skill._id}>{"• " + skill}</label>
                        </li>
                      ))}
                    </div>
                  );
                })}
                {props.element.skills.map((skill) => (
                  <li key={skill._id}>
                    <label key={skill._id}>{"• " + skill}</label>
                  </li>
                ))}
              </div>
            </Box>
          </CardActionArea>
          {height >= 300 && !showMore ? (
            <Stack justifyContent="flex-end" direction="row">
              <Button
                sx={{
                  textTransform: "capitalize",
                  color: themeMatchersLight.palette.info.dark,
                }}
                onClick={() => {
                  setMaxHeight("none");
                  setShowMore(true);
                }}
              >
                ...Voir plus
              </Button>
            </Stack>
          ) : null}
          {showMore ? (
            <Stack
              justifyContent="flex-end"
              direction="row"
              sx={{ backgroundColor: "white", overflow: "hidden" }}
            >
              <Button
                sx={{
                  textTransform: "capitalize",
                  color: themeMatchersLight.palette.info.dark,
                }}
                onClick={() => {
                  setMaxHeight("300px");
                  setShowMore(false);
                }}
              >
                ...Voir moins
              </Button>
            </Stack>
          ) : null}
        </Card>
      </div>
    );
  } else {
    return <div>error</div>;
  }
}
