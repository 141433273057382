import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Dispatch, SetStateAction, useContext } from "react";
import { TemplateModel } from "../../models/Template.model";
import { Grid } from "@mui/material";
import logo from "../../assets/nouveau_logo.png";
import { ProgramContext } from "../../context/program.context";
import {
  AssetsContext,
  EvaluationContext,
  GoalsContext,
  ModalitiesContext,
  ModulesContext,
  PrerequisitesContext,
  PublicsContext,
} from "../../context/element.context";
import { ProgramService } from "../../service/program/Program.service";
import { TemplateInterface } from "../../models/ITemplate.model";
import { ProgramFooter } from "../ProgramFooter/ProgramFooter";
import { Note } from "../Note/Note";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export function TemplatePreview(props: {
  open: boolean;
  setTemplate: Dispatch<SetStateAction<TemplateModel | null>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
  template: TemplateInterface | null;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setDisplayProgram: Dispatch<SetStateAction<boolean | null>>;
}) {
  const [, setProgram] = useContext(ProgramContext);
  const [, setGoals] = useContext(GoalsContext);
  const [, setModalities] = useContext(ModalitiesContext);
  const [, setPublics] = useContext(PublicsContext);
  const [, setPrerequisites] = useContext(PrerequisitesContext);
  const [, setEvaluation] = useContext(EvaluationContext);
  const [, setAssets] = useContext(AssetsContext);
  const [, setModules] = useContext(ModulesContext);

  const handleClose = () => {
    props.setTemplate(null);
    props.setOpen(false);
  };

  const chooseTemplate = () => {
    if (props.template) {
      ProgramService.startProgramFromTemplate(props.template._id).then(
        (program) => {
          setProgram(program);
          setGoals(program.goals);
          setModalities(program.modalities);
          setPublics(program.publics);
          setPrerequisites(program.prerequisites);
          setEvaluation(program.evaluation);
          setAssets(program.assets);
          setModules(program.modules);
          props.setLoading(false);
        }
      );
      props.setLoading(true);
      props.setDisplayProgram(true);
    } else {
      ProgramService.startProgramFromTemplate(null).then((program) => {
        setProgram(program);
        setGoals(program.goals);
        setModalities(program.modalities);
        setPublics(program.publics);
        setPrerequisites(program.prerequisites);
        setEvaluation(program.evaluation);
        setAssets(program.assets);
        setModules(program.modules);
        props.setLoading(false);
      });
      props.setLoading(true);
      props.setDisplayProgram(true);
    }
  };

  if (props.template) {
    return (
      <div>
        <Dialog
          open={props.open}
          fullWidth={true}
          maxWidth={"lg"}
          onClose={handleClose}
          scroll={"paper"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          sx={{
            width: "100%",
            minWidth: "80%",
          }}
        >
          <DialogTitle id="scroll-dialog-title">
            Visualisation du template
          </DialogTitle>
          <DialogContent dividers={true}>
            <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
              <div>
                <div>
                  <Grid container spacing={2}>
                    {/* First space = empty */}
                    <Grid item xs={2}></Grid>

                    {/* Second space = titles */}
                    <Grid item xs={8}>
                      <div>
                        <h1 id="program-title">{props.template.title}</h1>
                        <h2 id="program-subtitle">{props.template.topic}</h2>
                      </div>
                    </Grid>

                    {/* Third space = Matchers logo*/}
                    <Grid item xs={2}>
                      <img src={logo} id="program-logo" alt="logo" />
                    </Grid>
                  </Grid>
                </div>

                {/* -- OBJECTIFS -- */}
                <h2 id="title">Objectifs</h2>
                <div className="alinea">
                  {props.template.goals.length ? (
                    props.template.goals.map((goal) => (
                      <li key={goal._id}>
                        <label>{goal.text}</label>
                      </li>
                    ))
                  ) : (
                    <p style={{ fontStyle: "italic" }}>Aucun élément</p>
                  )}
                </div>

                {/* --- CONTENU --- */}
                <h2 id="title">Contenu</h2>
                <div className="alinea">
                  {/* --- Modules to display ? --- */}
                  {props.template.modules.length ? (
                    props.template.modules.map((module, index) => {
                      return (
                        // Display modules
                        <div>
                          <h3 className="flex-none titre2">
                            {`Module ${index + 1} : ${module.title}`}
                          </h3>
                          {module.modules.map((module, index) => {
                            return (
                              <div>
                                <h3 className="flex-none">{` ${index + 1} - ${
                                  module.title
                                }`}</h3>
  
                                <div className="alinea">
                                   {/* Display of skills for each submodule  */}
                                    {module.skills.map((skill) => {
                                      return (
                                        <li key={skill._id}>
                                          <label>{skill.text}</label>
                                        </li>
                                      );
                                    })}
                                </div>
                              </div>
                            );
                          })}

                          {/* Display skills */}
                          <div className="alinea">
                            {module.skills.map((skill) => {
                              return (
                                <li key={skill._id}>
                                  <label>{skill.text}</label>
                                </li>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p style={{ fontStyle: "italic" }}>Aucun contenu</p>
                  )}
                </div>

                {/* --- MODALITES PEDAGOGIQUES --- */}
                <h2 id="title">Modalités pédagogiques</h2>
                <div className="alinea">
                  {props.template.modalities.length ? (
                    props.template.modalities.map((modality) => (
                      <li key={modality._id}>
                        <label>{modality.text}</label>
                      </li>
                    ))
                  ) : (
                    <p style={{ fontStyle: "italic" }}>Aucun élément</p>
                  )}
                </div>

                {/* --- PUBLICS --- */}
                <h2 id="title">Publics</h2>
                <p>Cette formation est destinée à des: </p>
                <div className="alinea">
                  {props.template.publics.length ? (
                    props.template.publics.map((p) => (
                      <li key={p._id}>
                        <label>{p.text}</label>
                      </li>
                    ))
                  ) : (
                    <p style={{ fontStyle: "italic" }}>Aucun public spécifié</p>
                  )}
                </div>

                {/* --- PREREQUIS --- */}
                <h2 id="title">Prérequis</h2>
                <div className="alinea">
                {props.template.prerequisites.length ? (
                    props.template.prerequisites.map((prerequisite) => (
                      <li key={prerequisite._id}>
                        <label>{prerequisite.text}</label>
                      </li>
                    ))
                  ) : (
                    <p style={{ fontStyle: "italic" }}>Aucun élément</p>
                  )}
                </div>

                {/* --- EVALUATION --- */}
                <h2 id="title">Suivi et évaluation</h2>
                <div className="alinea">
                  {props.template.evaluation.length ? (
                    props.template.evaluation.map((e) => (
                      <li key={e._id}>
                        <label>{e.text}</label>
                      </li>
                    ))
                  ) : (
                    <p style={{ fontStyle: "italic" }}>Aucun élément</p>
                  )}
                </div>

                {/* --- ATOUTS --- */}
                <h2 id="title">Les atouts</h2>
                <div className="alinea">
                {props.template.assets.length ? (
                    props.template.assets.map((asset) => (
                      <li key={asset._id}>
                        <label>{asset.text}</label>
                      </li>
                    ))
                  ) : (
                    <p style={{ fontStyle: "italic" }}>Aucun élément</p>
                  )}

                </div>
                <div>
                  <Note />
                </div>
                <div>
                  <ProgramFooter />
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                borderRadius: "20px",
                textTransform: "capitalize",
                color: "black",
              }}
              onClick={handleClose}
            >
              Annuler
            </Button>
            <Button
              sx={{ borderRadius: "20px", textTransform: "capitalize" }}
              variant="contained"
              onClick={chooseTemplate}
              size="medium"
              endIcon={<ArrowForwardIosIcon />}
            >
              Choisir
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  } else {
    return (
      <div>
        <Dialog
          open={props.open}
          fullWidth={true}
          maxWidth={"md"}
          onClose={handleClose}
          scroll={"paper"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          sx={{
            width: "100%",
            minWidth: "80%",
          }}
        >
          <DialogTitle id="scroll-dialog-title">
            Visualisation du template
          </DialogTitle>
          <DialogContent dividers={true}>
            <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
              <p>
                Voulez vous partir d'un template vierge? Seulement les éléments
                du programme qui sont obligatoires pour un programme de
                formation seront ajoutés.
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                borderRadius: "20px",
                textTransform: "capitalize",
                color: "black",
              }}
              onClick={handleClose}
            >
              Annuler
            </Button>
            <Button
              sx={{ borderRadius: "20px", textTransform: "capitalize" }}
              variant="contained"
              onClick={chooseTemplate}
              size="medium"
              endIcon={<ArrowForwardIosIcon />}
            >
              Choisir
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
