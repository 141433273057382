import "./EmptyModule.css";
import React, { Dispatch, SetStateAction } from "react";
import { ElementModel, ElementType } from "../../models/Element.model";
import { ModuleModel } from "../../models/Module.model";

export function EmptyModule(props: {
  setModules: Dispatch<SetStateAction<ModuleModel[]>>;
  setSkills: Dispatch<SetStateAction<ElementModel[]>>;
  parentModule?: string;
}) {
  const handleAddElement = (event: React.MouseEvent<HTMLButtonElement>) => {
    let newElement = new ElementModel(
      "0",
      "",
      null,
      ElementType.SKILL,
      false,
      null
    );
    props.setSkills([newElement]);
  };

  const handleAddModule = (event: React.MouseEvent<HTMLButtonElement>) => {
    let newModule = new ModuleModel("0", "", [], [], null, null, 1);
    props.setModules([newModule]);
  };

  return (
    <React.Fragment>
      <span className="alinea3 inline">
        <button id="add-module" onClick={handleAddModule}>
          + Ajouter un sous module
        </button>
        <button id="add-element" onClick={handleAddElement}>
          + Ajouter une compétence
        </button>
      </span>
    </React.Fragment>
  );
}
