import { TurnedInNot } from "@mui/icons-material";
import {
  Box,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import ParticipantToolTip from "../ParticipantToolTip/ParticipantToolTip";
import React, { useState } from "react";
import DialogAddTrainer from "../DialogAddTrainer/DialogAddTrainer";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

export function Status(props: { status: any }) {
  const [click, setClick] = useState(false);

  return (
    <HtmlTooltip
      title={<ParticipantToolTip click={click} setClick={setClick} />}
    >
      <Box
        sx={{
          color: "secondary.dark",
        }}
      >
        {/* Status of the document */}
        <div
          style={{
            width: "250px",
            padding: "16px",
          }}
        >
          <TurnedInNot
            sx={{
              display: "inline-flex",
              verticalAlign: "top",
            }}
          />{" "}
          Etat du document : {props.status}
        </div>
        <DialogAddTrainer open={click} setOpen={setClick} />
      </Box>
    </HtmlTooltip>
  );
}
