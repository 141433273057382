import "./ProgramHeader.css"
import logo from "../../assets/nouveau_logo.png";
import {Grid} from "@mui/material";
import React, { useContext, useState} from "react";
import {ProgramContext} from "../../context/program.context";
import {ProgramService} from "../../service/program/Program.service";


export function ProgramHeader(props: {
    title: string;
    topic: string;
}){

    const [program, setProgram] = useContext(ProgramContext);
    const [title, setTitle] = useState(props.title);

    const handleTitleChange = (event: React.ChangeEvent<any>) => {
        if (program) {
            //program.updateTitle(event.currentTarget.value);
            ProgramService.updateTitleProgram(event.target.value);
        }
        setProgram(program)
    };

    const changeHandler = (event: React.ChangeEvent<any>) => {
        setTitle(event.target.value);
    }

    return(
        <div>
            <Grid container spacing={2}>
                {/* First space = empty */}
                <Grid item xs={2}>
                </Grid>

                {/* Second space = titles */}
                <Grid item xs={8}>
                    <div>
                        <textarea id="program-title" onBlur={handleTitleChange}
                               onChange={changeHandler} value={title}/>
                        <h1 id="program-subtitle">{props.topic}</h1>
                    </div>
                </Grid>

                {/* Third space = Matchers logo*/}
                <Grid item xs={2}>
                    <img src={logo} id="program-logo" alt="logo" />
                </Grid>
            </Grid>
        </div>
    );
}