import "./HoursTextField.css";
import React, { FocusEvent, useState } from "react";
import { Box, TextField, Tooltip } from "@mui/material";
import { ProgramService } from "../../service/program/Program.service";

export function HoursTextField(props: {
  hours: { nbHours: number; fixed: boolean };
}) {
  const [nbHours, setNbHours] = useState(props.hours.nbHours);

  const handleChange = (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => {
    setNbHours(parseInt(event.currentTarget.value));
  };

  const handleElementChange = (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => {
    ProgramService.updateHoursProgram(
      parseInt(event.currentTarget.value),
      props.hours?.fixed
    );
  };

  return (
    // <Box sx={{ display: "inline", float: "right", width: 400, mr: '20px'}}>
    <Box sx={{ float: "right", width: 220 }}>
      {props.hours.fixed ? (
        <Box sx={{ color: "info.dark", fontStyle: "italic" }}>
          <Tooltip title="Le nombre d'heures à été fixé par Matchers">
            <p>Durée de la formation (h) : {nbHours}</p>
          </Tooltip>
        </Box>
      ) : (
        <TextField
          sx={{ display: "inline right", float: "right", width: 220 }}
          id="outlined-number"
          label="Durée de la formation (h)"
          type="number"
          size="small"
          margin="normal"
          defaultValue={nbHours}
          onChange={handleChange}
          onBlur={handleElementChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    </Box>
  );
}
