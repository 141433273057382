import "./TitleModuleTextField.css";
import React, { Dispatch, SetStateAction, useState } from "react";
import { ModuleModel } from "../../models/Module.model";
import { ModuleService } from "../../service/program/Module.service";

export function TitleModuleTextField(props: {
  firstLevel: boolean;
  placeholder: string;
  size: number;
  module: ModuleModel;
  parentModule?: string;
  setModule: Dispatch<SetStateAction<ModuleModel>>;
  handleFocus: React.FocusEventHandler<HTMLInputElement>;
}) {
  const [title, setTitle] = useState(props.module.title);

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    setTitle(event.currentTarget.value);
  };

  const handleElementChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (props.module._id.length > 5) {
      ModuleService.updateModuleTitle(
        props.module._id,
        event.currentTarget.value
      );
    } else {
      if (props.firstLevel) {
        ModuleService.addModuleInTrainingProgram(
          event.currentTarget.value
        ).then((module) => {
          props.setModule(module);
        });
      } else {
        if (props.parentModule) {
          ModuleService.addModuleInModule(
            props.parentModule,
            event.currentTarget.value
          ).then((module) => {
            console.log(module);
            props.setModule(module);
          });
        }
      }
    }
  };

  return (
    <React.Fragment>
      <input
        type="text"
        className={props.firstLevel ? "textfield titre2" : "textfield titre3"}
        placeholder={props.placeholder}
        id={props.module._id}
        value={title}
        onChange={handleChange}
        onFocus={props.handleFocus}
        onBlur={handleElementChange}
      />
    </React.Fragment>
  );
}
