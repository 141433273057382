import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { Paper } from "@mui/material";

export function SearchBar({
  search,
  setSearch,
}: {
  search: string;
  setSearch: (search: string) => void;
}) {
  return (
    <div>
      <Paper
        sx={{
          p: "2px 4px",
          m: "auto",
          mt: "25px",
          display: "flex",
          width: 300,
          borderRadius: "18px",
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Rechercher..."
          inputProps={{ "aria-label": "recherche" }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />

        <SearchIcon sx={{ p: "10px" }} color="secondary" />
      </Paper>
    </div>
  );
}
