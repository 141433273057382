import { ProgramModel } from "../../models/Program.model";
import axios from "axios";
import { plainToInstance } from "class-transformer";
import { TrainerModel } from "../../models/Trainer.model";

export class ProgramService {
  public static getProgram(): Promise<ProgramModel> {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.REACT_APP_API_URL + "/training-program")
        .then((response) => {
          let program = response.data.program;
          let programs = plainToInstance(ProgramModel, program);
          console.log(programs);
          // @ts-ignore

          resolve(programs);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static getOldPrograms(): Promise<ProgramModel[]> {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.REACT_APP_API_URL + "/training-program/oldPrograms")
        .then((response) => {
          let program = response.data;
          let programs = plainToInstance(ProgramModel, program);
          resolve(programs);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static getSimilarPrograms(): Promise<ProgramModel[]> {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.REACT_APP_API_URL + "/training-program/getSimilar")
        .then((response) => {
          let program = response.data;
          let programs = plainToInstance(ProgramModel, program);
          resolve(programs);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static startProgramFromTemplate(
    templateId: string | null
  ): Promise<ProgramModel> {
    if (templateId) {
      return new Promise((resolve, reject) => {
        axios
          .patch(
            process.env.REACT_APP_API_URL + "/training-program/chooseTemplate",
            {
              templateId: templateId,
            }
          )
          .then((response) => {
            let program = response.data.program;
            let programs = plainToInstance(ProgramModel, program);
            // @ts-ignore
            resolve(programs);
          })
          .catch((error) => {
            reject(error);
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        axios
          .patch(
            process.env.REACT_APP_API_URL + "/training-program/chooseTemplate"
          )
          .then((response) => {
            let program = response.data.program;
            let programs = plainToInstance(ProgramModel, program);
            // @ts-ignore
            resolve(programs);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  }

  public static updateTitleProgram(title: string): Promise<ProgramModel> {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          process.env.REACT_APP_API_URL + "/training-program/updateTitle",
          { title: title }
        )
        .then((response) => {
          //TODO Remove console.log for production
          console.log(response.data.program);
          let program = response.data.program;
          let programs = plainToInstance(ProgramModel, program);
          console.log(programs);
          // @ts-ignore
          resolve(programs);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  public static updateHoursProgram(
    nbHours: number,
    fixed: boolean
  ): Promise<ProgramModel> {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          process.env.REACT_APP_API_URL + "/training-program/updateHours",
          { nbHours: nbHours, fixed: fixed }
        )
        .then((response) => {
          let program = response.data.program;
          let programs = plainToInstance(ProgramModel, program);
          // @ts-ignore
          resolve(programs);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  public static addTrainer(
    trainer: TrainerModel
  ): Promise<{ token: string; userId: string }> {
    return new Promise((resolve, reject) => {
      axios
        .patch<{ token: string; userId: string }>(
          process.env.REACT_APP_API_URL + "/training-program/addTrainer",
          trainer
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  public static validateProgram(): Promise<ProgramModel> {
    return new Promise((resolve, reject) => {
      axios
        .patch(process.env.REACT_APP_API_URL + "/training-program/validate")
        .then((response) => {
          let program = response.data.program;
          let programs = plainToInstance(ProgramModel, program);
          // @ts-ignore
          resolve(programs);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  public static inValidateProgram(): Promise<ProgramModel> {
    return new Promise((resolve, reject) => {
      axios
        .patch(process.env.REACT_APP_API_URL + "/training-program/invalidate")
        .then((response) => {
          let program = response.data.program;
          let programs = plainToInstance(ProgramModel, program);
          // @ts-ignore
          resolve(programs);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  public static submitProgram(): Promise<ProgramModel> {
    return new Promise((resolve, reject) => {
      axios
        .patch(process.env.REACT_APP_API_URL + "/training-program/submit")
        .then((response) => {
          let program = response.data.program;
          let programs = plainToInstance(ProgramModel, program);
          // @ts-ignore
          resolve(programs);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }
  public static finalValidation(
    canBeSuggested: boolean
  ): Promise<ProgramModel> {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          process.env.REACT_APP_API_URL + "/training-program/finalValidation",
          { canBeSuggested: canBeSuggested }
        )
        .then((response) => {
          let program = response.data.program;
          let programs = plainToInstance(ProgramModel, program);
          // @ts-ignore
          resolve(programs);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
