import "./PdfDocument.css";
import React, { useContext } from "react";
import {
  AssetsContext,
  EvaluationContext,
  GoalsContext,
  ModalitiesContext,
  ModulesContext,
  PrerequisitesContext,
  PublicsContext,
} from "../../context/element.context";
import { ProgramContext } from "../../context/program.context";
import { Box } from "@mui/material";

export function PdfDocument(props: { hidden: boolean }) {
  const [program] = useContext(ProgramContext);
  const [goals] = useContext(GoalsContext);
  const [modalities] = useContext(ModalitiesContext);
  const [publics] = useContext(PublicsContext);
  const [prerequisites] = useContext(PrerequisitesContext);
  const [evaluation] = useContext(EvaluationContext);
  const [assets] = useContext(AssetsContext);
  const [modules] = useContext(ModulesContext);

  if (program) {
    return (
      <div className={props.hidden ? "body hidden" : "body"}>
        <div className="alinea">
          <h1 id="program-title">{program.title}</h1>
          <h1 id="program-subtitle">{program.topic}</h1>
          <h2 id="title">Objectifs</h2>
          <div className="alinea">
            {goals.map((goal) => (
              <li key={goal._id}>
                <label>{goal.text}</label>
              </li>
            ))}
          </div>
          <h2 id="title">Contenu</h2>
          <div className="alinea">
            {modules.map((module, index) => {
              return (
                <div>
                  <h3 className="flex-none titre2">
                    {`Module ${index + 1} : ${module.title}`}
                  </h3>
                  {module.modules.map((module, index) => {
                    return (
                      <div>
                        <h3 className="flex-none">{` ${index + 1} - ${
                          module.title
                        }`}</h3>

                        <div className="alinea">
                          {module.skills.map((skill) => {
                            return (
                              <li key={skill._id}>
                                <label>{skill.text}</label>
                              </li>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                  <div className="alinea">
                    {module.skills.map((skill) => {
                      return (
                        <li key={skill._id}>
                          <label>{skill.text}</label>
                        </li>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>

          <h2 id="title">Modalités pédagogiques</h2>
          <div className="alinea">
            {modalities.map((modality) => (
              <li key={modality._id}>
                <label>{modality.text}</label>
              </li>
            ))}
          </div>
          <h2 id="title">Publics</h2>
          <p>Cette formation est destinée à des: </p>
          <div className="alinea">
            {publics.map((p) => (
              <li key={p._id}>
                <label>{p.text}</label>
              </li>
            ))}
          </div>
          <h2 id="title">Prérequis</h2>
          <div className="alinea">
            {prerequisites.map((prerequisite) => (
              <li key={prerequisite._id}>
                <label>{prerequisite.text}</label>
              </li>
            ))}
          </div>
          <h2 id="title">Suivi et évaluation</h2>
          <div className="alinea">
            {evaluation.map((e) => (
              <li key={e._id}>
                <label>{e.text}</label>
              </li>
            ))}
          </div>
          <h2 id="title">Les atouts</h2>
          <div className="alinea">
            {assets.map((asset) => (
              <li key={asset._id}>
                <label>{asset.text}</label>
              </li>
            ))}
          </div>
          <h2 style={{ fontSize: "18px" }}>Note</h2>
          <div>
            <p id="article-name">Article L. 6353-1 DU CODE DU TRAVAIL </p>
            <p id="article-content" className="note">
              « Les actions de formation professionnelle mentionnées à l'article
              L. 6313-1 sont réalisées conformément à un programme préétabli
              qui, en fonction d'objectifs déterminés, précise le niveau de
              connaissances préalables requis pour suivre la formation, les
              moyens pédagogiques, techniques et d'encadrement mis en œuvre
              ainsi que les moyens permettant de suivre son exécution et d'en
              apprécier les résultats… »
            </p>
          </div>
          <div className="report-footer-cell">
            <div className="footer-info">
              <div className={"page-footer"}>
                <Box sx={{ color: "primary.main" }} className="footer">
                  <hr />
                  <div style={{ fontSize: "10px" }}>
                    <p style={{ fontStyle: "bold" }}>
                      Pour toute correspondance :
                    </p>
                    <p>
                      MATCHERS – 120, Rue Adrien Proby – 34 090 Montpellier –
                      contact@matchers.fr – 09 72 26 43 99
                    </p>
                  </div>
                  <div>
                    <p>
                      CMC INSPIRATION S.A.S. –Via Innova – ZAE Espace Lunel
                      Littoral – 177b Av Louis Lumière 34 400 Lunel, France
                    </p>
                    <p>
                      SAS au capital de 15 000€ – R.C.S. Montpellier – Siren
                      849533849 – TVA intra. FR85849533849 - APE 6201Z – N° DA :
                      76-34-10490-34
                    </p>
                  </div>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <div>pas de programme</div>;
  }
}
