import { ModuleModel } from "../../models/Module.model";
import { Section } from "../Section/Section";
import "./Module.css";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from "react";
import { ElementType } from "../../models/Element.model";
import { TitleModuleTextField } from "../TitleModuleTextField/TitleModuleTextField";
import { IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { ModuleService } from "../../service/program/Module.service";
import { EmptyModule } from "../EmptyModule/EmptyModule";
import {
  ModuleSelected,
  SkillSelected,
  TypeContext,
} from "../../context/type.context";
import { useDrag, useDrop } from "react-dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {
  deleteInDB,
  ElementToDelete,
  ElementToDeleteContext,
} from "../../context/elementToDelete.context";
import DeletionAlert from "../DeletionAlert/DeletionAlert";
import { RoleType, UserContext } from "../../context/user.context";
import { useSnackbar } from "notistack";
import { DisplayHourContext } from "../../context/displayleHour.context";

export function Module(props: {
  firstLevel: boolean;
  module: ModuleModel;
  parentModule?: string;
  modules: ModuleModel[];
  setModules: Dispatch<SetStateAction<ModuleModel[]>>;
  index: number;
  swapObject: (
    item: ModuleModel,
    item2: ModuleModel,
    requestAPI: boolean
  ) => void;
  handleDelete: (module: ModuleModel, index: number) => void;
}): JSX.Element {
  const [module, setModule] = useState(props.module);
  const [modules, setModules] = useState(
    props.module.modules.sort((a, b) => {
      return a.number - b.number;
    })
  );
  const [displayHour] = useContext(DisplayHourContext);
  const [skills, setSkills] = useState(props.module.skills);
  const [selectedType, setSelectedType] = useContext(TypeContext);
  const [elementToDelete, setElementToDelete] = useContext(
    ElementToDeleteContext
  );
  const [open, setOpen] = useState(false);
  const [user] = useContext(UserContext);

  const handleDelete = (module: ModuleModel, index: number) => {
    if (module._id.length > 5) {
      if (elementToDelete) {
        deleteInDB(elementToDelete);
      }
      setOpen(true);
      setElementToDelete(new ElementToDelete(index, module, false));
    }
    let copy = [...modules];
    copy.splice(index, 1);
    setModules(copy);
  };
  const { enqueueSnackbar } = useSnackbar();
  const ErrorDisplay = useCallback(
    (err: any) => {
      switch (err.status) {
        case 500:
          enqueueSnackbar(err.data.error ? err.data.error : err.statusText, { variant: "error" });
          break;
        case 400:
          enqueueSnackbar(err.data.error ? err.data.error : err.statusText, { variant: "error" });
          break;
        default:
          enqueueSnackbar(err.statusText, { variant: "error" });
      }
    },
    [enqueueSnackbar]
  );

  const add = () => {
    const id = modules.length;
    let newModule = new ModuleModel(
      id.toString(),
      "",
      [],
      [],
      null,
      null,
      modules.length + 1
    );
    setModules([...modules, newModule]);
    props.setModules([...props.modules]);
  };
  const addModuleFromSugeestionBar = (module: ModuleModel) => {
    ModuleService.addModuleInModuleFromSuggestion(module._id, props.module._id)
      .then((module) => {
        setModules((previousState) => {
          return [...previousState, module];
        });
      })
      .catch(ErrorDisplay);
  };

  const handleFocus = () => {
    if (props.firstLevel && skills.length === 0) {
      setSelectedType(
        new ModuleSelected(props.firstLevel, module._id, modules, setModules)
      );
    } else {
      setSelectedType(new SkillSelected(module._id, skills, setSkills));
    }
  };

  const getInfo = (module: ModuleModel) => {
    if (module.writer) {
      return (
        "Ce module a été rédigé par " +
        module.writer.firstname +
        " " +
        module.writer.lastname
      );
    } else {
      return "Ce module a été rédigé par Matchers";
    }
  };
  const [, drag, preview] = useDrag(
    () => ({
      type: props.firstLevel ? "firstLevel" : "secondLevel",
      item: module,
      collect: (monitor) => {
        return {
          isDragging: monitor.isDragging(),
        };
      },
      end: (item, monitor) => {
        const didDrop = monitor.didDrop();
        if (didDrop) {
          props.swapObject(monitor.getItem(), props.module, true);
        }
      },
    }),
    [module, props.swapObject]
  );
  const [{ canDrop, isOverCurrent }, dropSuggestion] = useDrop(() => ({
    accept: ElementType.MODULE,
    drop: (item: ModuleModel) => {
      addModuleFromSugeestionBar(item);
    },
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
  }));
  let dropClass = "";
  if (isOverCurrent && !(props.firstLevel && props.module.skills.length > 0)) {
    dropClass = "selectedSection border-active";
  } else if (canDrop && !(props.firstLevel && props.module.skills.length > 0)) {
    dropClass = "border-active";
  }
  const [, drop] = useDrop(
    () => ({
      accept: props.firstLevel ? "firstLevel" : "secondLevel",
      hover(item: ModuleModel) {
        if (item._id !== module._id) {
          props.swapObject(item, module, false);
        }
      },
    }),
    [props.swapObject]
  );
  const [savedModules, setSavedModules] = useState(modules);
  const [duration, setDuration] = useState(
    module.duration ? module.duration : 0
  );
  const swapObject = useCallback(
    (item: ModuleModel, item2: ModuleModel, requestAPI: boolean) => {
      let copy = [...modules];
      const index = copy.indexOf(item);
      const index2 = copy.indexOf(item2);
      if (index !== -1 && index2 !== -1) {
        copy[index] = item2;
        copy[index2] = item;

        setModules(copy);
        if (requestAPI) {
          ModuleService.updateModuleOrderInModule(copy, module._id)
            .then(() => {
              setSavedModules(copy);
            })
            .catch((e) => {
              ErrorDisplay(e);
              setModules(savedModules);
            });
        }
      }
    },
    [
      modules,
      setModules,
      module._id,
      ErrorDisplay,
      savedModules,
      setSavedModules,
    ]
  );

  const handleDurationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDuration(parseInt(event.currentTarget.value));
  };

  const updateDuration = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (module._id.length > 5) {
      ModuleService.updateModuleDuration(
        module._id,
        parseInt(event.currentTarget.value)
      );
    }
  };

  if (props.firstLevel) {
    return (
      <div
        ref={(node) => {
          props.firstLevel && props.module.skills.length > 0
            ? preview(node)
            : preview(dropSuggestion(node));
        }}
        className={dropClass}
      >
        <div id="module" className={"flex-container module "} ref={drop}>
          <div className="delete">
            <IconButton
              aria-label="delete"
              size="small"
              id={module._id.length > 5 ? module._id : props.index.toString()}
              onClick={() => {
                props.handleDelete(module, props.index);
              }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </div>
          <div
            className="grid-container delete grab-cursor"
            ref={(node) => {
              drag(node);
            }}
          >
            <DragIndicatorIcon
              sx={{
                "&.MuiSvgIcon-root": {
                  margin: "auto",
                },
              }}
            />
          </div>
          <h3 className="flex-none titre2">
            {`Module ${props.index + 1} :`}&ensp;{" "}
          </h3>{" "}
          <TitleModuleTextField
            firstLevel={props.firstLevel}
            placeholder={""}
            size={2}
            module={module}
            setModule={setModule}
            handleFocus={handleFocus}
          />
        </div>
        {module._id.length > 5 && (
          <div className="alinea3">
            {displayHour ? (
              <TextField
                hidden={displayHour}
                sx={{ width: "140px" }}
                type="number"
                label={""}
                value={duration}
                inputProps={{ min: "0" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Durée</InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">h</InputAdornment>
                  ),
                }}
                size="small"
                onChange={handleDurationChange}
                onBlur={updateDuration}
              ></TextField>
            ) : null}
          </div>
        )}
        {modules.length > 0 && (
          <div
            className={
              selectedType instanceof ModuleSelected &&
              selectedType.id === module._id
                ? "border-active alinea"
                : "border alinea"
            }
          >
            {modules.map((m, index) => {
              return (
                <div key={m._id}>
                  <Module
                    firstLevel={false}
                    module={m}
                    modules={modules}
                    parentModule={module._id}
                    setModules={setModules}
                    index={index}
                    swapObject={swapObject}
                    handleDelete={handleDelete}
                  />
                </div>
              );
            })}
            {selectedType instanceof ModuleSelected &&
              selectedType.id === module._id && (
                <div className="alinea2 help-add-module">
                  Cliquez sur une suggestion et ajoutez un sous-module à ce
                  module
                </div>
              )}
            <button className="alinea" id="add-module" onClick={add}>
              + Ajouter <span className="bold">un sous module</span>
            </button>
            <DeletionAlert
              open={open}
              setOpen={setOpen}
              content={null}
              modules={modules}
              setContent={null}
              setModules={setModules}
            />
          </div>
        )}
        {skills.length > 0 && (
          <div>
            <Section
              content={skills}
              title={""}
              path={[props.index]}
              type={ElementType.SKILL}
              parentModule={module._id}
              setContent={setSkills}
            />
          </div>
        )}
        {modules.length === 0 && skills.length === 0 && module._id.length > 5 && (
          <div>
            <EmptyModule setModules={setModules} setSkills={setSkills} />
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div
        ref={(node) => {
          preview(drop(node));
        }}
      >
        {module._id.length < 5 ||
        (user !== null &&
          (user.role === RoleType.ADMIN ||
            user.role === RoleType.MAIN_TRAINER ||
            (user.role === RoleType.TRAINER &&
              user.user !== null &&
              module.writer !== null &&
              user.user._id === module.writer._id))) ? (
          <div>
            <div className="flex-container module">
              <div className="delete">
                <IconButton
                  aria-label="delete"
                  size="small"
                  id={
                    module._id.length > 5 ? module._id : props.index.toString()
                  }
                  onClick={() => {
                    props.handleDelete(module, props.index);
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </div>
              <div
                className="grid-container delete grab-cursor"
                ref={(node) => {
                  drag(node);
                }}
              >
                <DragIndicatorIcon
                  sx={{
                    "&.MuiSvgIcon-root": {
                      margin: "auto",
                    },
                  }}
                />
              </div>
              <h3 className="flex-none">{` ${props.index + 1} - `}</h3>
              <TitleModuleTextField
                firstLevel={props.firstLevel}
                placeholder={""}
                size={2}
                module={module}
                parentModule={props.parentModule}
                setModule={setModule}
                handleFocus={handleFocus}
              />
            </div>
            <div>
              <Section
                content={skills}
                title={""}
                path={[props.index]}
                type={ElementType.SKILL}
                parentModule={module._id}
                setContent={setSkills}
              />
            </div>
          </div>
        ) : (
          <div>
            <Tooltip title={getInfo(module)} followCursor>
              <div className="alinea2">
                <h3>{` ${props.index + 1} - ${module.title}`} </h3>
                <div className="alinea2">
                  {module.skills.map((skill) => {
                    return (
                      <li key={skill._id}>
                        <label>{skill.text}</label>
                      </li>
                    );
                  })}
                  {module.skills.length === 0 && (
                    <p className="alinea2 no-element">Aucun élément</p>
                  )}
                </div>
              </div>
            </Tooltip>
          </div>
        )}
      </div>
    );
  }
}
