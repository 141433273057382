import {
  Box,
  Button,
  Drawer,
  Fab,
  Fade,
  IconButton,
  List,
  ListItem,
  Stack,
  styled,
  Tooltip,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import { SuggestionCard } from "../SuggestionCard/SuggestionCard";
import "./SuggestionField.css";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { SearchBar } from "../SearchBar/SearchBar";
import { ModuleSuggestionInterface } from "../../models/IModuleSuggestion.model";
import { TypeContext } from "../../context/type.context";
import { SuggestionService } from "../../service/suggestion/Suggestion.service";
import { HistoricService } from "../../service/historicSuggestion/HistoricSuggestion.service";
import HelpIcon from "@mui/icons-material/Help";
import { useSnackbar } from "notistack";

const drawerWidth = 400;
const NoContent = styled(Box)({
  textAlign: "center",
  padding: "20px",
});

const DrawerButton = styled("div", {
  shouldForwardProp: (prop) => prop !== "open",
})<{
  open?: boolean;
}>(({ theme, open }) => ({
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth - 5}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

export default function SuggestionField({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const [pageSuggestion, setPageSuggestion] = React.useState(true);
  const [suggestions, setSuggestions] = React.useState<
    ModuleSuggestionInterface[]
  >([]);
  const [historic, setHistoric] = React.useState<ModuleSuggestionInterface[]>(
    []
  );
  const [suggestionsSearchResponse, setSuggestionsSearchResponse] =
    React.useState<ModuleSuggestionInterface[]>([]);
  const [suggestionsAdapted, setSuggestionsAdapted] = React.useState<
    ModuleSuggestionInterface[]
  >([]);
  const [selectedType] = useContext(TypeContext);
  const { enqueueSnackbar } = useSnackbar();

  const [search, setSearch] = React.useState("");
  useEffect(() => {
    SuggestionService.getSuggestions(selectedType, true)
      .then((suggestionsRequest) => {
        setSuggestionsAdapted(suggestionsRequest);
      })
      .catch((error) => {
        switch (error.status) {
          case 500:
            enqueueSnackbar(error.data.error? error.data.error : error.statusText , { variant: "error" });
            break;
          default:
            enqueueSnackbar(error.statusText, { variant: "error" });
        }
      });

    SuggestionService.getSuggestions(selectedType, false)
      .then((suggestionsRequest) => {
        setSuggestionsSearchResponse(suggestionsRequest);
      })
      .catch((error) => {
        switch (error.status) {
          case 500:
            enqueueSnackbar(error.data.error? error.data.error : error.statusText, { variant: "error" });
            break;
          default:
            enqueueSnackbar(error.statusText, { variant: "error" });
        }
      });
    HistoricService.getHistoric(selectedType)
      .then((suggestionsRequest) => {
        setHistoric(suggestionsRequest);
      })
      .catch((error) => {
        switch (error.status) {
          case 500:
            enqueueSnackbar(error.data.error? error.data.error : error.statusText, { variant: "error" });
            break;
          default:
            enqueueSnackbar(error.statusText, { variant: "error" });
        }
      });
  }, [selectedType, enqueueSnackbar]);

  useEffect(() => {
    if (search.length === 0) {
      setSuggestions(suggestionsAdapted);
    } else {
      setSuggestions(
        suggestionsSearchResponse.filter((suggestion) => {
          return suggestion.search(search.trim());
        })
      );
    }
  }, [search, suggestionsSearchResponse, suggestionsAdapted]);

  const handleDrawer = () => {
    setOpen(!open);
  };
  const goPageHistory = () => {
    setSuggestions(historic);
    setPageSuggestion(false);
  };
  const goPageSuggestion = () => {
    if (search.length === 0) {
      setSuggestions(suggestionsAdapted);
    } else {
      setSuggestions(
        suggestionsSearchResponse.filter((suggestion) => {
          return suggestion.search(search.trim());
        })
      );
    }
    setPageSuggestion(true);
  };

  return (
    <Box sx={{ display: "flex", marginRight: "45px" }}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
            borderWidth: 0,
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <div className="toolBarDiv"></div>
        <Stack
          direction="row"
          sx={{ bgcolor: "white", padding: "0" }}
          className="border-radius"
        >
          {/* ----- HISTORY BUTTON ----- */}
          <Button
            color="secondary"
            variant="contained"
            sx={{
              color: "white",
              fontSize: "1.3rem",
              fontStyle: "italic",
              textTransform: "capitalize",
              borderRadius: "40px 60px 0% 0%",
              "&.Mui-disabled": {
                color: "white",
                bgcolor: "primary.main",
              },
            }}
            fullWidth={true}
            disabled={!pageSuggestion}
            onClick={goPageHistory}
            size="small"
          >
            Historique
          </Button>

          {/* ----- SUGGESTION BUTTON ----- */}
          <Button
            color="secondary"
            variant="contained"
            sx={{
              color: "white",
              fontSize: "1.3rem",
              fontStyle: "italic",
              textTransform: "capitalize",
              borderRadius: "60px 40px 0% 0%",
              "&.Mui-disabled": {
                color: "white",
                bgcolor: "primary.main",
              },
            }}
            fullWidth={true}
            disabled={pageSuggestion}
            onClick={goPageSuggestion}
            size="small"
          >
            Suggestion
          </Button>
        </Stack>

        {/* ----- TOOLTIP ----- */}
        <Box
          sx={{ overflowX: "hidden", bgcolor: "primary.main", height: "100%" }}
        >
          <Box sx={{ bgcolor: "primary.main" }}>
            <IconButton size="small">
              <HelpIcon
                fontSize="medium"
                sx={{ color: "warning.main", mr: "4px", ml: "16px" }}
              />

              <Tooltip
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                arrow
                placement="bottom-start"
                //Display the right text depending if we're on the suggestion or history side of the panel
                title={
                  pageSuggestion
                    ? "Les suggestions de contenu vous sont présentées en fonction du thème de la formation. En tapant dans la barre de recherche, vous accédez à l'ensemble des suggestions, tous thèmes confondus."
                    : "Retrouvez ici tout le contenu que vous avez ajouté, dans ce programme comme dans vos programmes précédents."
                }
              >
                <Button
                  sx={{
                    color: "white",
                    fontSize: "14px",
                    fontStyle: "italic",
                    textTransform: "none",
                    mt:"3px"
                  }}
                >
                  {pageSuggestion
                    ? "Qu'est ce que les suggestions ?"
                    : "Qu'est ce que l'historique ?"}
                </Button>
              </Tooltip>
            </IconButton>
          </Box>

          {/* ----- SEARCHBAR ----- */}
          {pageSuggestion && (
            <SearchBar search={search} setSearch={setSearch} />
          )}
          <List>
            {suggestions && suggestions.length > 0 ? (
              suggestions.map((module, index) => (
                <ListItem sx={{ display: "block" }} key={index}>
                  <SuggestionCard
                    element={module}
                    suggestionList={suggestions}
                    setSuggestionList={setSuggestions}
                    index={index}
                  />
                </ListItem>
              ))
            ) : (
              <NoContent color="white">
                <div className="display-inlineflex">
                  <SentimentVeryDissatisfiedIcon sx={{ marginRight: "10px" }} />
                  Pas de contenu suggérable
                </div>
              </NoContent>
            )}
          </List>
        </Box>
      </Drawer>

      {/* ----- DRAWER BUTTON ----- */}
      <DrawerButton open={open}>
        <div className="drawerButton">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Fab
              sx={{
                "&.MuiFab-root": {
                  paddingLeft: "6px",
                },
              }}
              color="warning"
              onClick={handleDrawer}
            >
              {open ? (
                <ArrowBackIos color="primary" />
              ) : (
                <ArrowForwardIos color="primary" />
              )}
            </Fab>
          </Box>
        </div>
      </DrawerButton>
    </Box>
  );
}
