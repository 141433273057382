import { useEffect, useState } from "react";
import { ElementModel, ElementType } from "./models/Element.model";
import {
  ModuleSelected,
  SkillSelected,
  TypeContext,
} from "./context/type.context";
import { ProgramModel, ProgramStatus } from "./models/Program.model";
import {
  ElementToDelete,
  ElementToDeleteContext,
} from "./context/elementToDelete.context";
import { User, UserContext } from "./context/user.context";
import { useNavigate, useParams } from "react-router-dom";
import { AuthInterceptor } from "./middleware/Interceptor/Auth.interceptor";
import { ProgramService } from "./service/program/Program.service";
import { ProgramContext } from "./context/program.context";
import {
  AssetsContext,
  EvaluationContext,
  GoalsContext,
  ModalitiesContext,
  ModulesContext,
  PrerequisitesContext,
  PublicsContext,
} from "./context/element.context";
import { ModuleModel } from "./models/Module.model";
import { UserService } from "./service/user/User.service";
import App from "./App";

export default function ProviderProgram() {
  let { token } = useParams();
  AuthInterceptor(token!);
  // program
  const [program, setProgram] = useState<ProgramModel | null>(null);
  const [selectedType, setSelectedType] = useState<
    ElementType | ModuleSelected | SkillSelected
  >(ElementType.GOAL);

  const [goals, setGoals] = useState<ElementModel[]>([]);
  const [modalities, setModalities] = useState<ElementModel[]>([]);
  const [publics, setPublics] = useState<ElementModel[]>([]);
  const [prerequisites, setPrerequisites] = useState<ElementModel[]>([]);
  const [evaluation, setEvaluation] = useState<ElementModel[]>([]);
  const [assets, setAssets] = useState<ElementModel[]>([]);
  const [modules, setModules] = useState<ModuleModel[]>([]);
  const [elementToDelete, setElementToDelete] =
    useState<ElementToDelete | null>(null);
  let navigate = useNavigate();

  useEffect(() => {
    ProgramService.getProgram()
      .then((returnProgram: ProgramModel) => {
        setProgram(returnProgram);
        setGoals(returnProgram.goals);
        setModalities(returnProgram.modalities);
        setPublics(returnProgram.publics);
        setPrerequisites(returnProgram.prerequisites);
        setEvaluation(returnProgram.evaluation);
        setAssets(returnProgram.assets);
        setModules(returnProgram.modules);
        setLoading(false);
        setDisplayProgram(returnProgram.status !== ProgramStatus.NOT_STARTED);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
        setDisplayProgram(false);
        navigate("/", { replace: true });
      });
  }, [navigate]);

  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    UserService.getUser().then((returnUser) => {
      setUser(returnUser);
    });
  }, []);

  const [loading, setLoading] = useState(true);
  const [displayProgram, setDisplayProgram] = useState<boolean | null>(null);

  return (
    <ProgramContext.Provider value={[program, setProgram]}>
      <TypeContext.Provider value={[selectedType, setSelectedType]}>
        <GoalsContext.Provider value={[goals, setGoals]}>
          <ModalitiesContext.Provider value={[modalities, setModalities]}>
            <PublicsContext.Provider value={[publics, setPublics]}>
              <PrerequisitesContext.Provider
                value={[prerequisites, setPrerequisites]}
              >
                <EvaluationContext.Provider value={[evaluation, setEvaluation]}>
                  <AssetsContext.Provider value={[assets, setAssets]}>
                    <ElementToDeleteContext.Provider
                      value={[elementToDelete, setElementToDelete]}
                    >
                      <ModulesContext.Provider value={[modules, setModules]}>
                        <UserContext.Provider value={[user, setUser]}>
                          <App
                            displayProgram={displayProgram}
                            loading={loading}
                            setDisplayProgram={setDisplayProgram}
                            setLoading={setLoading}
                          />
                        </UserContext.Provider>
                      </ModulesContext.Provider>
                    </ElementToDeleteContext.Provider>
                  </AssetsContext.Provider>
                </EvaluationContext.Provider>
              </PrerequisitesContext.Provider>
            </PublicsContext.Provider>
          </ModalitiesContext.Provider>
        </GoalsContext.Provider>
      </TypeContext.Provider>
    </ProgramContext.Provider>
  );
}
