import "./ElementTextField.css";
import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { ElementModel, ElementType } from "../../models/Element.model";
import { SkillSelected, TypeContext } from "../../context/type.context";
import { ElementService } from "../../service/program/Element.service";
import { useSnackbar } from "notistack";

export function ElementTextField(props: {
  placeholder: string;
  size: number;
  index: number;
  element: ElementModel;
  content: ElementModel[];
  setContent: Dispatch<SetStateAction<ElementModel[]>>;
  parentModule?: string;
  swapObject: (item: ElementModel, item2: ElementModel) => void;
}) {
  const [text, setText] = useState(props.element.text);

  const [, setSelectedType] = useContext(TypeContext);
  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    setText(event.currentTarget.value);
  };
  const { enqueueSnackbar } = useSnackbar();
  const ErrorDisplay = (err: any) => {
    switch (err.status) {
      case 500:
        enqueueSnackbar(err.data.error ? err.data.error : err.statusText, { variant: "error" });
        break;
      case 400:
        enqueueSnackbar(err.data.error ? err.data.error : err.statusText, { variant: "error" });
        break;
      default:
        enqueueSnackbar(err.statusText, { variant: "error" });
    }
  };

  const handleElementChange = (event: React.FormEvent<HTMLInputElement>) => {
    let previousText = props.element.text;
    setText(event.currentTarget.value);
    if (event.currentTarget.value) {
      if (props.element._id.length > 5) {
        switch (props.element.type) {
          case ElementType.GOAL:
            ElementService.updateGoal(
              props.element._id,
              event.currentTarget.value
            ).catch((err) => {
              ErrorDisplay(err);
              setText(previousText);
            });
            break;
          case ElementType.MODALITY:
            ElementService.updateModality(
              props.element._id,
              event.currentTarget.value
            ).catch((err) => {
              ErrorDisplay(err);
              setText(previousText);
            });
            break;
          case ElementType.PREREQUISITE:
            ElementService.updatePrerequisite(
              props.element._id,
              event.currentTarget.value
            ).catch((err) => {
              ErrorDisplay(err);
              setText(previousText);
            });
            break;
          case ElementType.PUBLIC:
            ElementService.updatePublic(
              props.element._id,
              event.currentTarget.value
            ).catch((err) => {
              ErrorDisplay(err);
              setText(previousText);
            });
            break;
          case ElementType.ASSET:
            ElementService.updateAsset(
              props.element._id,
              event.currentTarget.value
            ).catch((err) => {
              ErrorDisplay(err);
              setText(previousText);
            });
            break;
          case ElementType.EVALUATION:
            ElementService.updateEvaluation(
              props.element._id,
              event.currentTarget.value
            ).catch((err) => {
              ErrorDisplay(err);
              setText(previousText);
            });
            break;
          case ElementType.SKILL:
            ElementService.updateSkill(
              props.element._id,
              event.currentTarget.value
            ).catch((err) => {
              ErrorDisplay(err);
              setText(previousText);
            });
            break;
        }
      } else {
        switch (props.element.type) {
          //TODO: Refactor
          case ElementType.GOAL:
            ElementService.addGoal(event.currentTarget.value);
            break;
          case ElementType.MODALITY:
            ElementService.addModality(event.currentTarget.value);
            break;
          case ElementType.PREREQUISITE:
            ElementService.addPrerequisite(event.currentTarget.value);
            break;
          case ElementType.PUBLIC:
            ElementService.addPublic(event.currentTarget.value);
            break;
          case ElementType.ASSET:
            ElementService.addAsset(event.currentTarget.value);
            break;
          case ElementType.EVALUATION:
            ElementService.addEvaluation(event.currentTarget.value);
            break;
          case ElementType.SKILL:
            if (props.parentModule) {
              ElementService.addSkill(
                props.parentModule,
                event.currentTarget.value
              );
            }
            break;
        }
      }
    }
  };
  const handleFocus = () => {
    if (props.element.type === ElementType.SKILL) {
      if (props.parentModule) {
        setSelectedType(
          new SkillSelected(props.parentModule, props.content, props.setContent)
        );
      } else {
        console.log("No parents module");
      }
    } else {
      setSelectedType(props.element.type);
    }
  };
  /* TODO: Reusable code for drag and drop of skill between module
  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: props.element.getType(),
      item: props.element,
      options: {
        dropEffect: "copy",
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const didDrop = monitor.didDrop();
        if (!didDrop) {
        }
      },
    }),
    [props.element, props.swapObject]
  );
  const [, drop] = useDrop(
    () => ({
      accept: props.element.getType(),
      hover(item: ElementModel, monitor) {
        if (item._id !== props.element._id) {
        }
      },
    }),
    [props.swapObject]
  );
  */

  return (
    <React.Fragment>
      <input
        type="text"
        className="textfield"
        placeholder={props.placeholder}
        id={props.element._id}
        value={text}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleElementChange}
      />
    </React.Fragment>
  );
}
