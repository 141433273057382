import axios from "axios";
import { ElementModel, ElementType } from "../../models/Element.model";
import { plainToInstance } from "class-transformer";
import { ModuleModel } from "../../models/Module.model";
import { ModuleSuggestionInterface } from "../../models/IModuleSuggestion.model";
import { ModuleSelected, SkillSelected } from "../../context/type.context";

export class SuggestionService {
  public static getSuggestions(
    type: ElementType | ModuleSelected | SkillSelected,
    adapted: boolean
  ): Promise<ModuleSuggestionInterface[]> {
    let suggestionUrl = "";
    if (type instanceof ModuleSelected) {
      adapted
        ? (suggestionUrl = "adaptedModules")
        : (suggestionUrl = "modules");
    } else if (type instanceof SkillSelected) {
      suggestionUrl = "skill";
    } else {
      switch (type) {
        case ElementType.GOAL:
          adapted ? (suggestionUrl = "adaptedGoal") : (suggestionUrl = "goal");
          break;
        case ElementType.EVALUATION:
          suggestionUrl = "evaluation";
          break;
        case ElementType.ASSET:
          suggestionUrl = "asset";
          break;
        case ElementType.MODALITY:
          suggestionUrl = "modality";
          break;
        case ElementType.PUBLIC:
          suggestionUrl = "public";
          break;
        case ElementType.PREREQUISITE:
          suggestionUrl = "prerequisite";
          break;
        default:
          console.log("No type found");
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.REACT_APP_API_URL + "/suggestion/" + suggestionUrl)
        .then((response) => {
          if (response.data) {
            if (type instanceof ModuleSelected) {
              resolve(plainToInstance(ModuleModel, response.data));
            }
            resolve(plainToInstance(ElementModel, response.data));
          } else {
            resolve(response.data);
          }
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }
}
