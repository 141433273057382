import { ClientModel } from "./Client.model";
import { TrainerModel } from "./Trainer.model";
import { ElementModel, ElementType } from "./Element.model";
import { ModuleModel } from "./Module.model";
import { Transform, TransformFnParams, Type } from "class-transformer";
import { ModuleSuggestionInterface } from "./IModuleSuggestion.model";
import { TemplateInterface } from "./ITemplate.model";

export enum ProgramStatus {
  NOT_STARTED = "Non commencé",
  EDITION = "Édition",
  COLLABORATION = "Collaboration",
  VALIDATE = "Validé",
}
function transformStatus(type: TransformFnParams): ProgramStatus {
  let typeString = type.value as string;
  switch (typeString) {
    case "NOT_STARTED":
      return ProgramStatus.NOT_STARTED;
    case "EDITION":
      return ProgramStatus.EDITION;
    case "COLLABORATION":
      return ProgramStatus.COLLABORATION;
    case "VALIDATE":
      return ProgramStatus.VALIDATE;
    default:
      return ProgramStatus.EDITION;
  }
}
export enum OPCO {
  AKTO = "AKTO",
  OTHER = "AUTRE",
}
export class ProgramModel implements TemplateInterface {
  public _id: string;
  public title: string;
  public topic: string;
  @Type(() => ClientModel)
  public client: ClientModel;
  @Type(() => TrainerModel)
  public mainTrainer: TrainerModel;
  @Type(() => TrainerModel)
  public trainers: TrainerModel[];
  @Transform(transformStatus)
  public status: ProgramStatus;
  public opco: OPCO;
  public isArchived: boolean;
  @Type(() => ElementModel)
  public goals: ElementModel[];
  @Type(() => ModuleModel)
  public modules: ModuleModel[];
  @Type(() => ElementModel)
  public modalities: ElementModel[];
  @Type(() => ElementModel)
  public publics: ElementModel[];
  @Type(() => ElementModel)
  public prerequisites: ElementModel[];
  @Type(() => ElementModel)
  public assets: ElementModel[];
  @Type(() => ElementModel)
  public evaluation: ElementModel[];
  public hours: { nbHours: number; fixed: boolean };
  @Type(() => TrainerModel)
  public notValidated: TrainerModel[];
  public canBeSuggested: boolean;
  constructor(
    _id: string,
    title: string,
    topic: string,
    client: ClientModel,
    mainTrainer: TrainerModel,
    trainers: TrainerModel[],
    status: ProgramStatus,
    isArchived: boolean,
    goals: ElementModel[],
    modules: ModuleModel[],
    modalities: ElementModel[],
    publics: ElementModel[],
    prerequisites: ElementModel[],
    assets: ElementModel[],
    evaluation: ElementModel[],
    opco: OPCO,
    hours: { nbHours: number; fixed: boolean },
    notValidated: TrainerModel[],
    canBeSuggested: boolean
  ) {
    this._id = _id;
    this.title = title;
    this.topic = topic;
    this.client = client;
    this.mainTrainer = mainTrainer;
    this.trainers = trainers;
    this.status = status;
    this.isArchived = isArchived;
    this.goals = goals;
    this.modules = modules;
    this.modalities = modalities;
    this.publics = publics;
    this.prerequisites = prerequisites;
    this.assets = assets;
    this.evaluation = evaluation;
    this.opco = opco;
    this.hours = hours;
    this.notValidated = notValidated;
    this.canBeSuggested = canBeSuggested;
  }

  updateProgram(index: number | number[], type: ElementType, value: string) {
    if (typeof index === "number") {
      switch (type) {
        case ElementType.GOAL:
          this.updateElement(index, this.goals, value);
          break;
        case ElementType.EVALUATION:
          this.updateElement(index, this.evaluation, value);
          break;
        case ElementType.MODALITY:
          this.updateElement(index, this.modalities, value);
          break;
        case ElementType.ASSET:
          this.updateElement(index, this.assets, value);
          break;
        case ElementType.PREREQUISITE:
          this.updateElement(index, this.prerequisites, value);
          break;
        case ElementType.PUBLIC:
          this.updateElement(index, this.publics, value);
          break;
      }
    } else {
      switch (type) {
        case ElementType.MODULE:
          this.updateModule(index, value);
          break;
        case ElementType.SKILL:
          this.updateSkill(index, value);
          break;
      }
    }
  }
  updateElement(rank: number, elements: ElementModel[], value: string) {
    elements[rank].text = value;
  }
  updateModule(index: number[], value: string) {
    if (index.length === 1) {
      this.modules[index[0]].title = value;
    } else {
      this.modules[index[0]].modules[index[1]].title = value;
    }
  }
  updateSkill(index: number[], value: string) {
    if (index.length === 2) {
      this.modules[index[0]].skills[index[1]].text = value;
    } else {
      this.modules[index[0]].modules[index[1]].skills[index[2]].text = value;
    }
  }
  addElement(element: ModuleSuggestionInterface) {
    if (element instanceof ElementModel) {
      switch (element.type) {
        case ElementType.GOAL:
          this.goals.push(element);
          break;
        case ElementType.EVALUATION:
          this.evaluation.push(element);
          break;
        case ElementType.MODALITY:
          this.modalities.push(element);
          break;
        case ElementType.ASSET:
          this.assets.push(element);
          break;
        case ElementType.PREREQUISITE:
          this.prerequisites.push(element);
          break;
        case ElementType.PUBLIC:
          this.publics.push(element);
          break;
      }
    }
  }
  copy(): ProgramModel {
    return new ProgramModel(
      this._id,
      this.title,
      this.topic,
      this.client,
      this.mainTrainer,
      this.trainers,
      this.status,
      this.isArchived,
      this.goals,
      this.modules,
      this.modalities,
      this.publics,
      this.prerequisites,
      this.assets,
      this.evaluation,
      this.opco,
      this.hours,
      this.notValidated,
      this.canBeSuggested
    );
  }
  public addTrainer(trainer: TrainerModel) {
    this.trainers.push(trainer);
    this.notValidated.push(trainer);
  }
}
