import "./TemplateChoice.css";
import React, { useContext } from "react";
import { Box } from "@mui/material";
import Headers from "../../../Component/Headers/Headers";

import { UserContext } from "../../../context/user.context";
import InfoToUser from "../../../Component/InfoToUser/InfoToUser";

export function TemplateChoiceTrainer() {
  const [user] = useContext(UserContext);

  // If the user is not the main trainer, he is a normal trainer
  return (
    <div>
      <Headers
        title={
          user && user.user
            ? "Bienvenue " + user.user.firstname + " " + user.user.lastname
            : "Bienvenue inconnu"
        }
        save={null}
      />

      <Box sx={{ paddingTop: "130px" }}>
        {/* Information to display to the trainer */}
        <InfoToUser
          text={
            "L'édition du programme de formation n'a pas encore commencé, un mail vous sera envoyé lorsque vous serez en mesure d'éditer le programme."
          }
          type={"warning"}
        />
      </Box>
    </div>
  );
}
