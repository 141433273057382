import React from "react";
import { ElementModel, ElementType } from "../models/Element.model";
import { ModuleModel } from "../models/Module.model";
import { ElementService } from "../service/program/Element.service";
import { ModuleService } from "../service/program/Module.service";

export class ElementToDelete {
  public index: number;
  public element: ElementModel | ModuleModel;
  public firstLevel: boolean | null;

  constructor(
    index: number,
    element: ElementModel | ModuleModel,
    firstLevel: boolean | null = null
  ) {
    this.index = index;
    this.element = element;
    this.firstLevel = firstLevel;
  }
}

export const deleteInDB = async (elementToDelete: ElementToDelete | null) => {
  if (elementToDelete && elementToDelete.element instanceof ElementModel) {
    const element = elementToDelete.element;
    if (element._id.length > 5) {
      switch (element.type) {
        case ElementType.GOAL:
          await ElementService.deleteGoal(element._id);
          break;
        case ElementType.ASSET:
          await ElementService.deleteAsset(element._id);
          break;
        case ElementType.EVALUATION:
          await ElementService.deleteEvaluation(element._id);
          break;
        case ElementType.PREREQUISITE:
          await ElementService.deletePrerequisite(element._id);
          break;
        case ElementType.PUBLIC:
          await ElementService.deletePublic(element._id);
          break;
        case ElementType.MODALITY:
          await ElementService.deleteModality(element._id);
          break;
        case ElementType.SKILL:
          await ElementService.deleteSkill(element._id);
      }
    }
  } else if (
    elementToDelete &&
    elementToDelete.element instanceof ModuleModel
  ) {
    const element = elementToDelete.element;
    if (element._id.length > 5) {
      if (elementToDelete.firstLevel) {
        await ModuleService.deleteModuleOfTrainingProgram(element._id);
      } else {
        await ModuleService.deleteModuleOfModule(element._id);
      }
    }
  }
};

export const ElementToDeleteContext = React.createContext<
  [
    ElementToDelete | null,
    React.Dispatch<React.SetStateAction<ElementToDelete | null>>
  ]
>([null, () => {}]);
