import { ProgramModel, OPCO } from '../../../models/Program.model';
import Headers from '../../../Component/Headers/Headers';
import {
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Switch,
  Tooltip,
} from '@mui/material';
import SuggestionField from '../../../Component/SuggestionField/SuggestionField';
import { ActionButton } from '../../../Component/ActionButton/ActionButton';
import SaveIcon from '@mui/icons-material/Save';
import {
  deleteInDB,
  ElementToDeleteContext,
} from '../../../context/elementToDelete.context';
import SendIcon from '@mui/icons-material/Send';
import { ProgramService } from '../../../service/program/Program.service';
import { HoursTextField } from '../../../Component/HoursTextField/HoursTextField';
import { Status } from '../../../Component/Status/Status';
import { Program } from '../../../Component/Program/Program';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ProgramContext } from '../../../context/program.context';
import { PdfService } from '../../../service/pdf/Pdf.service';
import { DisplayHourContext } from '../../../context/displayleHour.context';
import { useSnackbar } from 'notistack';
import { BootstrapTooltip } from '../../../Component/Tooltip/BootstrapTooltip';
import { Info } from '@mui/icons-material';

export default function NotValidatedProgram(props: {
  program: ProgramModel;
  setProgram: Dispatch<SetStateAction<ProgramModel | null>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  displayProgram: boolean | null;
  setDisplayProgram: Dispatch<SetStateAction<boolean | null>>;
}) {
  const [isSave, setIsSave] = useState(true);
  const [openSuggestion, setOpenSuggestion] = React.useState(true);
  const [elementToDelete, setElementToDelete] = useContext(
    ElementToDeleteContext
  );
  const [program, setProgram] = useContext(ProgramContext);
  const [displayHours, setDisplayHours] = useState(
    props.program.opco === OPCO.AKTO ? true : false
  );

  useEffect(() => {
    setIsSave(elementToDelete === null);
  }, [elementToDelete]);

  const createPDF = () => {
    ProgramService.getProgram().then((returnProgram: ProgramModel) => {
      PdfService.generatePdf(returnProgram).save(returnProgram.title + '.pdf');
    });
  };
  const { enqueueSnackbar } = useSnackbar();
  const submitProgram = () => {
    ProgramService.submitProgram()
      .then((program) => {
        setProgram(program);
      })
      .catch((e) => {
        switch (e.status) {
          case 400:
            enqueueSnackbar(e.data.error, { variant: 'error' });
            break;

          case 403:
            enqueueSnackbar("Vous n'avez pas les droits appropriés", {
              variant: 'error',
            });
            break;

          default:
            enqueueSnackbar(e.data.error, { variant: 'error' });
        }
      });
  };

  return (
    <DisplayHourContext.Provider value={[displayHours, setDisplayHours]}>
      <div>
        <div>
          <Headers title={'Édition de programme'} save={isSave} />
          <Box
            sx={{
              display: 'flex',
              paddingTop: '130px',
            }}
          >
            <SuggestionField
              open={openSuggestion}
              setOpen={setOpenSuggestion}
            />
            <Box sx={{ width: '100%' }}>
              {/*  ---- TOP BUTTONS ----  */}
              <Grid container alignItems="center">
                <ActionButton
                  label={'save'}
                  text={'Sauvegarder'}
                  icon={SaveIcon}
                  onClick={() =>
                    deleteInDB(elementToDelete).then(() => {
                      setElementToDelete(null);
                      setIsSave(true);
                    })
                  }
                  disable={false}
                />

                <Tooltip
                  disableInteractive={
                    program ? program.notValidated.length < 0 : true
                  }
                  title={
                    'Tout les formatteurs doivent avoir validé le programme avant de le soumettre au client'
                  }
                  followCursor
                >
                  <div>
                    <ActionButton
                      label={'submit'}
                      text={'Soumettre'}
                      icon={SendIcon}
                      onClick={submitProgram}
                      disable={program ? program.notValidated.length > 0 : true}
                    />
                  </div>
                </Tooltip>
                <Grid
                  container
                  item
                  xs={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <BootstrapTooltip
                    title={
                      'Pour une formation de 1 à 14h il faut minimum 3 modules, entre 14h à 21h minimum 4 modules, et 21 à 40h minimum 6 modules, Au delà toutes les 20h de formations rajouter 1 module'
                    }
                  >
                    <div>
                      <IconButton
                        color="primary"
                        aria-label="add to shopping cart"
                      >
                        <Info color="warning" />
                      </IconButton>
                    </div>
                  </BootstrapTooltip>
                </Grid>

                <Grid item xs />
                {/* ---- STATUS AND NB HOURS OF THE PROGRAM ---- */}
                <Stack direction="row" alignItems="center" spacing={2}>
                  {props.program.opco === OPCO.AKTO ? (
                    <FormControlLabel
                      control={
                        <Switch
                          defaultChecked={true}
                          value={!displayHours}
                          onChange={() => {
                            setDisplayHours(!displayHours);
                          }}
                        ></Switch>
                      }
                      label={'Afficher les heures'}
                    />
                  ) : null}
                  <HoursTextField hours={props.program.hours} />
                  <Status status={props.program.status} />
                </Stack>
              </Grid>

              {/* ---- PROGRAM ---- */}
              <Program program={props.program} setProgram={props.setProgram} />
              {/*  ---- BOTTOM BUTTONS ----  */}
              <Stack direction="row">
                <ActionButton
                  label={'save'}
                  text={'Sauvegarder'}
                  icon={SaveIcon}
                  onClick={() =>
                    deleteInDB(elementToDelete).then(() => {
                      setElementToDelete(null);
                      setIsSave(true);
                    })
                  }
                  disable={false}
                />

                <Tooltip
                  disableInteractive={
                    program ? program.notValidated.length < 0 : true
                  }
                  title={
                    'Tout les formatteurs doivent avoir validé le programme avant de le soumettre au client'
                  }
                  followCursor
                >
                  <div>
                    <ActionButton
                      label={'submit'}
                      text={'Soumettre'}
                      icon={SendIcon}
                      onClick={submitProgram}
                      disable={program ? program.notValidated.length > 0 : true}
                    />
                  </div>
                </Tooltip>
                <ActionButton
                  label={'download'}
                  text={'Télécharger (PDF)'}
                  icon={FileDownloadIcon}
                  onClick={createPDF}
                  disable={false}
                />
              </Stack>
            </Box>
          </Box>
        </div>
      </div>
    </DisplayHourContext.Provider>
  );
}
