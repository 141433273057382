import { Chip, Grid } from "@mui/material";
import logo from "../../assets/logo-matchers.png";
import "./Headers.css";
import React from "react";

function Headers(props: { title: string; save: boolean | null }) {
  return (
    <Grid spacing={3} className="Headers" container>
      <Grid container item xs={2} justifyContent="center">
        <img src={logo} className="Headers-logo" alt="logo" />
      </Grid>
      <Grid container item xs={7} justifyContent="center" alignItems="center">
        <h1 className="Headers-title">{props.title}</h1>
      </Grid>
      <Grid item xs={2}>
        {props.save !== null && (
          <div>
            {props.save ? (
              <Chip
                sx={{
                  marginTop: "20px",
                  bgcolor: "success.light",
                }}
                className="save"
                label="Sauvegardé"
              />
            ) : (
              <Chip
                sx={{
                  marginTop: "20px",
                  bgcolor: "error.light",
                }}
                className="not-save"
                label="Non sauvegardé"
              />
            )}
          </div>
        )}
      </Grid>
    </Grid>
  );
}
export default Headers;
