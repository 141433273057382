import Headers from "../../Component/Headers/Headers";
import { ProgramModel, ProgramStatus } from "../../models/Program.model";
import { Box, Stack } from "@mui/material";
import InfoToUser from "../../Component/InfoToUser/InfoToUser";
import { Status } from "../../Component/Status/Status";
import { PdfDocument } from "../../Component/PdfDocument/PdfDocument";
import { ActionButton } from "../../Component/ActionButton/ActionButton";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import React from "react";
import { ProgramService } from "../../service/program/Program.service";
import { PdfService } from "../../service/pdf/Pdf.service";

export default function ClientPage(props: {
  program: ProgramModel;
  setProgram: (program: ProgramModel) => void;
}) {
  const createPDF = () => {
    ProgramService.getProgram().then((returnProgram: ProgramModel) => {
      PdfService.generatePdf(returnProgram).save(returnProgram.title + ".pdf");
    });
  };
  return (
    <div>
      <Headers
        title={
          props.program.status === ProgramStatus.NOT_STARTED
            ? "Programme inacessible"
            : "Visualisation du programme"
        }
        save={null}
      />

      <Box sx={{ paddingTop: "130px" }}>
        {/* If the program is not started */}
        {props.program && props.program.status === ProgramStatus.NOT_STARTED ? (
          // Information
          <InfoToUser
            text={
              "L'édition du programme n'a pas commencé, vous serez notifié par mail lorsque vous pourrez accéder au document."
            }
            type={"warning"}
          />
        ) : (
          // If the program is already started
          <div>
            {/* Information to display to the client */}
            <InfoToUser
              text={
                  "Merci d'avoir chosi Matchers, vous pouvez dès à présent télécharger le programme via le bouton prévu à cet effet en bas de page."
              }
              type={"info"}
            />

            {/* ---- STATUS OF THE PROGRAM ---- */}
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
              spacing={2}
            >
              <Status status={props.program.status}></Status>
            </Stack>

            {/* Display PDF document */}
            <Box
              className="program"
              sx={{
                bgcolor: "info.light",
                borderRadius: "16px",
                marginLeft: "40px",
                marginRight: "40px",
                padding: "20px",
              }}
            >
              <PdfDocument hidden={false} />
            </Box>

            {/* Download PDF button */}
            <Box sx={{ marginLeft: "40px" }}>
              <ActionButton
                label={"download"}
                text={"Télécharger (PDF)"}
                icon={FileDownloadIcon}
                onClick={createPDF}
                disable={false}
              />
            </Box>
          </div>
        )}
      </Box>
    </div>
  );
}
