import { ProgramModel, ProgramStatus } from "../../models/Program.model";
import React, { Dispatch, SetStateAction } from "react";

import { TemplateChoiceMainTrainer } from "./TemplateChoice/TemplateChoice";
import NotValidatedProgram from "./NotValidatedProgram/NotValidatedProgram";
import CollaborationMainTrainerPage from "./CollaborationTrainerPage/CollaborationTrainerPage";

export default function MainTrainerPage(props: {
  program: ProgramModel;
  setProgram: Dispatch<SetStateAction<ProgramModel | null>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  displayProgram: boolean | null;
  setDisplayProgram: Dispatch<SetStateAction<boolean | null>>;
}) {
  if (
    (props.displayProgram !== null && !props.displayProgram) ||
    props.program.status === ProgramStatus.NOT_STARTED
  ) {
    return (
      <TemplateChoiceMainTrainer
        topic={props.program.topic}
        setLoading={props.setLoading}
        setDisplayProgram={props.setDisplayProgram}
      />
    );
  } else if (props.program.status === ProgramStatus.EDITION) {
    // le formateur n'a pas encore validé le programme et le programme est encore en édition
    return (
      <NotValidatedProgram
        program={props.program}
        setProgram={props.setProgram}
        loading={props.loading}
        setLoading={props.setLoading}
        displayProgram={props.displayProgram}
        setDisplayProgram={props.setDisplayProgram}
      />
    );
  } else if (props.program.status === ProgramStatus.COLLABORATION) {
    return <CollaborationMainTrainerPage />;
  } else if (props.program.status === ProgramStatus.VALIDATE) {
    return <div>Programme validé et fermé</div>;
  } else {
    return <div>Pas de programme</div>;
  }
}
