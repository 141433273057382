import { Type } from "class-transformer";
import { ElementModel } from "./Element.model";
import { ModuleModel } from "./Module.model";
import { TemplateInterface } from "./ITemplate.model";

export class TemplateModel implements TemplateInterface {
  public _id: string;
  public title: string;
  public topic: string;
  @Type(() => ElementModel)
  public goals: ElementModel[];
  @Type(() => ModuleModel)
  public modules: ModuleModel[];
  @Type(() => ElementModel)
  public modalities: ElementModel[];
  @Type(() => ElementModel)
  public publics: ElementModel[];
  @Type(() => ElementModel)
  public prerequisites: ElementModel[];
  @Type(() => ElementModel)
  public assets: ElementModel[];
  @Type(() => ElementModel)
  public evaluation: ElementModel[];
  public hours: { nbHours: number; fixed: boolean };
  constructor(
    _id: string,
    title: string,
    topic: string,
    goals: ElementModel[],
    modules: ModuleModel[],
    modalities: ElementModel[],
    publics: ElementModel[],
    prerequisites: ElementModel[],
    assets: ElementModel[],
    evaluation: ElementModel[],
    hours: { nbHours: number; fixed: boolean }
  ) {
    this._id = _id;
    this.title = title;
    this.topic = topic;
    this.goals = goals;
    this.modules = modules;
    this.modalities = modalities;
    this.publics = publics;
    this.prerequisites = prerequisites;
    this.assets = assets;
    this.evaluation = evaluation;
    this.hours = hours;
  }
}
