import { ModuleModel } from "../../models/Module.model";
import "./ModuleInAProgram.css";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from "react";
import { ProgramModel } from "../../models/Program.model";
import { Module } from "../Module/Module";
import { ModuleSelected, TypeContext } from "../../context/type.context";
import { ModuleService } from "../../service/program/Module.service";
import DeletionAlert from "../DeletionAlert/DeletionAlert";
import {
  deleteInDB,
  ElementToDelete,
  ElementToDeleteContext,
} from "../../context/elementToDelete.context";
import { useSnackbar } from "notistack";
import { useDrop } from "react-dnd";
import { ElementType } from "../../models/Element.model";

export function ModuleInAProgram(props: {
  hours: { nbHours: number; fixed: boolean };
  firstLevel: boolean;
  modules: ModuleModel[];
  path?: number[];
  setProgram: Dispatch<SetStateAction<ProgramModel | null>>;
  setModules: Dispatch<SetStateAction<ModuleModel[]>>;
  parentModule?: string;
}): JSX.Element {
  const [selectedType, setSelectedType] = useContext(TypeContext);
  const [open, setOpen] = useState(false);
  const [elementToDelete, setElementToDelete] = useContext(
    ElementToDeleteContext
  );
  const { enqueueSnackbar } = useSnackbar();
  const ErrorDisplay = useCallback(
    (err: any) => {
      switch (err.status) {
        case 500:
          enqueueSnackbar(err.data.error ? err.data.error : err.statusText, { variant: "error" });
          break;
        case 400:
          enqueueSnackbar(err.data.error ? err.data.error : err.statusText, { variant: "error" });
          break;
        default:
          enqueueSnackbar(err.statusText, { variant: "error" });
      }
    },
    [enqueueSnackbar]
  );

  const add = () => {
    const id = props.modules.length;
    let newModule = new ModuleModel(
      id.toString(),
      "",
      [],
      [],
      null,
      null,
      props.modules.length + 1
    );
    props.setModules([...props.modules, newModule]);
  };

  const errorMessageModule = () => {
    const h = props.hours.nbHours;
    let nbModules = 0;
    if (h > 0 && h < 15) {
      nbModules = 3;
    } else if (h > 14 && h < 22) {
      nbModules = 4;
    } else if (h > 21 && h < 41) {
      nbModules = 6;
    } else if (h > 41) {
      const moduleToAdd = Math.trunc((h - 41) / 20);
      nbModules = 6 + moduleToAdd;
    }

    return nbModules - props.modules.length > 0 ? `Vous devez ajouter au minimum ${nbModules - props.modules.length} modules` : 'Suffisamment de modules ont été ajouté';
  }

  const handleDelete = (module: ModuleModel, index: number) => {
    if (module._id.length > 5) {
      if (elementToDelete) {
        deleteInDB(elementToDelete);
      }
      setOpen(true);
      setElementToDelete(new ElementToDelete(index, module, props.firstLevel));
    }
    let copy = [...props.modules];
    copy.splice(index, 1);
    props.setModules(copy);
  };

  const handleFocus = () => {
    // TODO : revoir model
    setSelectedType(
      new ModuleSelected(false, null, props.modules, props.setModules)
    );
  };
  const [savedModules, setSavedModules] = useState(props.modules);
  const swapObject = useCallback(
    (item: ModuleModel, item2: ModuleModel, requestAPI: boolean) => {
      let copy = [...props.modules];
      const index = copy.indexOf(item);
      const index2 = copy.indexOf(item2);
      copy[index] = item2;
      copy[index2] = item;
      props.setModules(copy);
      if (requestAPI) {
        ModuleService.updateModuleOrderInProgram(copy)
          .then(() => {
            setSavedModules(copy);
          })
          .catch((e) => {
            ErrorDisplay(e);
            props.setModules(savedModules);
          });
      }
    },
    [props, ErrorDisplay, savedModules, setSavedModules]
  );
  const addModuleFromSugeestionBar = (module: ModuleModel) => {
    ModuleService.addModuleInTrainingProgramFromSuggestion(module._id)
      .then((module) => {
        props.setModules((previousState) => {
          return [...previousState, module];
        });
      })
      .catch(ErrorDisplay);
  };
  const [{ canDrop, isOverCurrent }, drop] = useDrop(() => ({
    accept: ElementType.MODULE,
    drop: (item: ModuleModel, monitor) => {
      const didDrop = monitor.didDrop();
      if (didDrop) {
        return;
      }
      addModuleFromSugeestionBar(item);
    },
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
  }));
  let dropClass = "";
  if (isOverCurrent) {
    dropClass = "selectedSection border-active";
  } else if (canDrop) {
    dropClass = "border-active";
  }

  return (
    <div>
      <h2 id="title" onClick={handleFocus}>
        Contenu
      </h2>
      <div className={dropClass}>
        <div
          className={
            selectedType instanceof ModuleSelected && selectedType.id === null
              ? "border-active "
              : "border "
          }
          ref={drop}
        >
          {props.modules.map((moduleFirstLevel, index) => {
            return (
              <div className="" key={moduleFirstLevel._id}>
                <Module
                  firstLevel={true}
                  module={moduleFirstLevel}
                  modules={props.modules}
                  setModules={props.setModules}
                  index={index}
                  swapObject={swapObject}
                  handleDelete={handleDelete}
                />
              </div>
            );
          })}
          {selectedType instanceof ModuleSelected &&
            selectedType.id === null && (
              <div className="alinea help-add-module">
                Cliquez sur une suggestion et ajoutez un module au contenu
              </div>
            )}
        </div>
        <button className="alinea" id="add-module" onClick={add}>
          + Ajouter <span className="bold">un module</span>
        </button>
        <p>{errorMessageModule()}</p>
        <DeletionAlert
          open={open}
          setOpen={setOpen}
          content={null}
          modules={props.modules}
          setContent={null}
          setModules={props.setModules}
        />
      </div>
    </div>
  );
}
