import Headers from '../../../Component/Headers/Headers';
import { ProgramModel, ProgramStatus } from '../../../models/Program.model';
import { Box, Grid, Stack } from '@mui/material';
import { Status } from '../../../Component/Status/Status';
import { PdfDocument } from '../../../Component/PdfDocument/PdfDocument';
import { ActionButton } from '../../../Component/ActionButton/ActionButton';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import React, { Dispatch, SetStateAction } from 'react';
import { ProgramService } from '../../../service/program/Program.service';
import { PdfService } from '../../../service/pdf/Pdf.service';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function ValidatedProgram(props: {
  program: ProgramModel;
  setProgram: (program: ProgramModel) => void;
  setValidated: Dispatch<SetStateAction<boolean>>;
}) {
  const createPDF = () => {
    ProgramService.getProgram().then((returnProgram: ProgramModel) => {
      PdfService.generatePdf(returnProgram).save(returnProgram.title + '.pdf');
    });
  };
  return (
    <div>
      <Headers
        title={
          props.program.status === ProgramStatus.NOT_STARTED
            ? 'Programme inacessible'
            : 'Visualisation du programme'
        }
        save={null}
      />
      {/* ---- STATUS OF THE PROGRAM ---- */}
      <Box sx={{ marginLeft: '100px', marginRight: '100px' }}>
        <Grid container alignItems="center">
          <ActionButton
            label={'unvalidate'}
            text={'Dévalider'}
            icon={CheckCircleIcon}
            onClick={() => {
              ProgramService.inValidateProgram().then((program) => {
                props.setProgram(program);
              });
              props.setValidated(false);
            }}
            disable={false}
          />

          <Grid item xs />
          {/* ---- STATUS AND NB HOURS OF THE PROGRAM ---- */}
          <Stack direction="row" alignItems="center" spacing={2}>
            <Status status={props.program.status} />
          </Stack>
        </Grid>
      </Box>
      {/* Display PDF document */}
      <Box
        className="program"
        sx={{
          bgcolor: 'info.light',
          borderRadius: '16px',
          marginLeft: '100px',
          marginRight: '100px',
          padding: '20px',
        }}
      >
        <PdfDocument hidden={false} />
      </Box>
      {/* Download PDF button */}
      <Box sx={{ marginLeft: '100px' }}>
        <ActionButton
          label={'download'}
          text={'Télécharger (PDF)'}
          icon={FileDownloadIcon}
          onClick={createPDF}
          disable={false}
        />
      </Box>
    </div>
  );
}
