import { Box } from "@mui/material";
import { Section } from "../Section/Section";
import { Note } from "../Note/Note";
import { ProgramFooter } from "../ProgramFooter/ProgramFooter";
import "./Program.css";
import { ProgramModel } from "../../models/Program.model";
import { ProgramHeader } from "../ProgramHeader/ProgramHeader";
import { ModuleInAProgram } from "../ModuleInAProgram/ModuleInAProgram";
import React, { Dispatch, SetStateAction, useContext } from "react";
import { ElementType } from "../../models/Element.model";
import {
  AssetsContext,
  EvaluationContext,
  GoalsContext,
  ModalitiesContext,
  ModulesContext,
  PrerequisitesContext,
  PublicsContext,
} from "../../context/element.context";

export function Program(props: {
  program: ProgramModel | null;
  setProgram: Dispatch<SetStateAction<ProgramModel | null>>;
}) {
  // TODO : l'importer directement dans les sous élements ou on en a besoin
  const [goals, setGoals] = useContext(GoalsContext);
  const [modalities, setModalities] = useContext(ModalitiesContext);
  const [publics, setPublics] = useContext(PublicsContext);
  const [prerequisites, setPrerequisites] = useContext(PrerequisitesContext);
  const [evaluation, setEvaluation] = useContext(EvaluationContext);
  const [assets, setAssets] = useContext(AssetsContext);
  const [modules, setModules] = useContext(ModulesContext);

  if (props.program) {
    return (
      <Box
        className="program"
        sx={{
          bgcolor: "info.light",
          borderRadius: "16px",
          marginRight: "20px",
          padding: "20px",
        }}
      >
        {/* Header */}
        <ProgramHeader
          title={props.program.title}
          topic={props.program.topic}
        />

        {/* program's sections */}
        <Section
          content={goals}
          title={"Objectifs"}
          type={ElementType.GOAL}
          setProgram={props.setProgram}
          setContent={setGoals}
        />

        <ModuleInAProgram
          hours={props.program.hours}
          firstLevel={true}
          setProgram={props.setProgram}
          setModules={setModules}
          modules={modules}
        />

        <Section
          content={modalities}
          title={"Modalités pédagogiques"}
          type={ElementType.MODALITY}
          setProgram={props.setProgram}
          setContent={setModalities}
        />
        <Section
          content={publics}
          title={"Publics"}
          type={ElementType.PUBLIC}
          setProgram={props.setProgram}
          setContent={setPublics}
        />
        <Section
          content={prerequisites}
          title={"Prérequis"}
          type={ElementType.PREREQUISITE}
          setProgram={props.setProgram}
          setContent={setPrerequisites}
        />
        <Section
          content={evaluation}
          title={"Suivi et évaluation"}
          type={ElementType.EVALUATION}
          setProgram={props.setProgram}
          setContent={setEvaluation}
        />
        <Section
          content={assets}
          title={"Les atouts"}
          type={ElementType.ASSET}
          setProgram={props.setProgram}
          setContent={setAssets}
        />
        <Note />

        {/* Footer */}
        <ProgramFooter />
      </Box>
    );
  } else {
    return <></>;
  }
}
