import Headers from "../../../Component/Headers/Headers";
import { Box, Tooltip } from "@mui/material";
import InfoToUser from "../../../Component/InfoToUser/InfoToUser";
import React, { useContext, useState } from "react";
import { ActionButton } from "../../../Component/ActionButton/ActionButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ConfirmationDialog from "../../../Component/ConfirmationDialog/ConfirmationDialog";
import { ProgramContext } from "../../../context/program.context";

export default function CollaborationMainTrainerPage() {
  const [open, setOpen] = useState(false);
  const [program] = useContext(ProgramContext);

  return (
    <div>
      <Headers title={"Page de collaboration"} save={null} />

      <Box sx={{ paddingTop: "130px" }}>
        {/* If the program is not started */}
        <InfoToUser text={"Bientôt disponible"} type={"warning"} />
        <Tooltip
          title={
            "Tout les formatteurs doivent avoir validé le programme avant de faire la validation finale"
          }
          followCursor
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
          >
            <ActionButton
              label={"validate"}
              text={"Validation finale"}
              icon={CheckCircleIcon}
              onClick={() => {
                setOpen(true);
              }}
              disable={program ? program.notValidated.length > 0 : true}
            />
          </Box>
        </Tooltip>
        <ConfirmationDialog open={open} setOpen={setOpen}/>
      </Box>
    </div>
  );
}
