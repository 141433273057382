import { Box, Fab } from "@mui/material";
import React from "react";

export function ActionButton(props: {
  label: string;
  text: string;
  icon: any;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disable: boolean;
}) {
  const Icon = props.icon;
  return (
    <Box sx={{ display: "inline-block", p: 2, paddingLeft: "5px" }}>
      {/* Button to display */}
      <Fab
        sx={{
          color: "#fff",
          textTransform: "capitalize",
        }}
        disabled={props.disable}
        variant="extended"
        size="medium"
        aria-label={props.label}
        color="secondary"
        onClick={props.onClick}
      >
        {/* Button's text and icon*/}
        {props.text}
        <Icon sx={{ m: 1, color: "#fff" }} />
      </Fab>
    </Box>
  );
}
