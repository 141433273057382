import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import React, { Dispatch, SetStateAction, useContext } from "react";
import { ElementModel } from "../../models/Element.model";
import { ElementToDeleteContext } from "../../context/elementToDelete.context";
import { ModuleModel } from "../../models/Module.model";
import { deleteInDB } from "../../context/elementToDelete.context";

export default function DeletionAlert(props: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  content: ElementModel[] | null;
  modules: ModuleModel[] | null;
  setContent: Dispatch<SetStateAction<ElementModel[]>> | null;
  setModules: Dispatch<SetStateAction<ModuleModel[]>> | null;
}) {
  const [elementToDelete, setElementToDelete] = useContext(
    ElementToDeleteContext
  );

  const handleDelete = () => {
    deleteInDB(elementToDelete);
    props.setOpen(false);
    setElementToDelete(null);
  };

  const handleCancel = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    console.log("annulation");
    if (reason === "clickaway") {
      return;
    }
    if (
      elementToDelete &&
      elementToDelete.element instanceof ElementModel &&
      props.setContent &&
      props.content
    ) {
      console.log(elementToDelete.element);
      let copy = [...props.content];
      copy.splice(elementToDelete.index, 0, elementToDelete.element);
      props.setContent(copy);
    } else if (
      elementToDelete &&
      elementToDelete.element instanceof ModuleModel &&
      props.setModules &&
      props.modules
    ) {
      let copy = [...props.modules];
      copy.splice(elementToDelete.index, 0, elementToDelete.element);
      props.setModules(copy);
    }
    setElementToDelete(null);
    props.setOpen(false);
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleCancel}>
        ANNULER
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleDelete}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={props.open && elementToDelete !== null}
        autoHideDuration={6000}
        message="Element supprimé"
        action={action}
      />
    </div>
  );
}
