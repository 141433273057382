import Headers from "../../../Component/Headers/Headers";
import { Box } from "@mui/material";
import InfoToUser from "../../../Component/InfoToUser/InfoToUser";
import React, { useContext, useState } from "react";
import { ActionButton } from "../../../Component/ActionButton/ActionButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { RoleType, UserContext } from "../../../context/user.context";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { ProgramService } from "../../../service/program/Program.service";
import { ProgramContext } from "../../../context/program.context";
import { ProgramStatus } from "../../../models/Program.model";

export default function CollaborationTrainerPage() {
  const [program, setProgram] = useContext(ProgramContext);
  const [user] = useContext(UserContext);
  const [validatedCollaboration, setValidatedCollaboration] = useState(
    program !== null &&
      program.status === ProgramStatus.COLLABORATION &&
      !(
        user &&
        (user.role === RoleType.MAIN_TRAINER || user.role === RoleType.ADMIN)
      ) &&
      !(
        user &&
        true &&
        program.notValidated.filter(function (e) {
          return user.user && e._id === user.user._id;
        }).length > 0
      )
  );

  return (
    <div>
      <Headers title={"Page de collaboration"} save={null} />
      <Box sx={{ paddingTop: "130px" }}>
        {/* If the program is not started */}
        <InfoToUser text={"Bientôt disponible"} type={"warning"} />

        <div>
          <Box display="flex" justifyContent="center" alignItems="center">
            <p>
              {validatedCollaboration
                ? "Le programme après collaboration est validé, le formateur principale peut faire la validation finale"
                : "Le programme après collaboration n'est pas encore validé "}
            </p>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <ActionButton
              label={"validate"}
              text={validatedCollaboration ? "Dévalider" : "Valider"}
              icon={
                validatedCollaboration
                  ? CheckCircleIcon
                  : RadioButtonUncheckedIcon
              }
              onClick={() => {
                if (validatedCollaboration) {
                  ProgramService.inValidateProgram().then((program) => {
                    setProgram(program);
                    setValidatedCollaboration(false);
                  });
                } else {
                  ProgramService.validateProgram().then((program) => {
                    setProgram(program);
                    setValidatedCollaboration(true);
                  });
                }
              }}
              disable={false}
            />
          </Box>
        </div>
      </Box>
    </div>
  );
}
