import { Box, Card, CardContent } from "@mui/material";
import { TemplateModel } from "../../models/Template.model";
import { TemplateInterface } from "../../models/ITemplate.model";

export function TemplateCard(props: {
  title: string;
  template: TemplateInterface | null;
  handleClick: (template: TemplateModel | null) => void;
}) {
  return (
    <Box
      className="card"
      sx={{
        display: "inline-block",
        margin: "10px",
      }}
    >
      <Card
        sx={{
          width: "250px",
          height: "150px",
        }}
      >
        <CardContent
          onClick={() => {
            props.handleClick(props.template);
          }}
          sx={{
            fontSize: 16,
            textAlign: "center",

            // Animation to underline the card
            "&:after": {
              content: '""',
              position: "absolute",
              width: "100%",
              transform: "scaleX(0)",
              height: "2px",
              bottom: "0",
              left: "0",
              backgroundColor: "secondary.main",
              transformOrigin: "bottom right",
              transition: "transform 0.25s ease-out",
            },

            ":hover:after": {
              transform: "scaleX(1)",
              transformOrigin: "bottom left",
            },
          }}
        >
          <h4 style={{ verticalAlign: "middle" }}> {props.title} </h4>
        </CardContent>
      </Card>
    </Box>
  );
}
