import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Dispatch, SetStateAction, useContext } from "react";
import { ProgramService } from "../../service/program/Program.service";
import { ProgramContext } from "../../context/program.context";
import { useSnackbar } from "notistack";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmationDialog(props: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const [, setProgram] = useContext(ProgramContext);
  const { enqueueSnackbar } = useSnackbar();
  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          props.setOpen(false);
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Valider le programme?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Vous êtes sur le point de valider le programme de formation, vous ne
            pourrez plus faire de modification après cette validation. Avant de
            valider, nous voudrions savoir si vous acceptez que ce programme
            soit réutilisé et proposé à de futurs formateurs ayant une
            thématique proche de la votre?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              props.setOpen(false);
            }}
          >
            Annuler
          </Button>
          <Button
            onClick={() => {
              ProgramService.finalValidation(false).then((program) => {
                setProgram(program);
              });
            }}
          >
            Je refuse et je valide
          </Button>
          <Button
            onClick={() => {
              ProgramService.finalValidation(true).then((program) => {
                setProgram(program);
                enqueueSnackbar('Programmé validé avec succès, vous pouvez quitter cette page.', { variant: 'success' });
              }).catch((e) => {
                enqueueSnackbar('Programme déjà validé, vous pouvez quitter cette page', { variant: 'error' });
              }).finally(() => {
                props.setOpen(false);
              })
            }}
          >
            J'accepte et je valide
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
